<!-- 系统用户 -->
<template>
  <div class="user">
    <div class="top_journal">
      <el-form ref="form" :model="queryInfo" >
       
        <el-row>
          <el-col :span="24"
            ><div class="rling">
              <el-form-item label="认领单位：">
                <sysunit @onChange="onChanges" />
              </el-form-item></div
          ></el-col>
        </el-row>
      </el-form>
    </div>

      <div class="row">
        <el-button
          type="primary"
          @click="addUser"
          v-if="$hasPerms('systemUser:add')"
          :disabled="addBtnDisabled"
          >新增</el-button
        >
        <div class="seach_box">
          <el-input placeholder="请输入名称" v-model="queryInfo.userName" class="input-with-select">
            <el-button class="seach" slot="append" @click="search()">搜索</el-button>
          </el-input>
        </div>
      </div>
      <el-table
        v-loading="loading"
        :data="listData"
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        @sort-change="sortChanged"
      >
        <el-table-column label="ID" align="center">
          <template v-slot="{ row }">
            <div>G000{{ row.id }}</div>
          </template>
        </el-table-column>
        <el-table-column label="手机号" prop="mobile" align="center"></el-table-column>
        <el-table-column label="真实姓名" prop="userName" align="center"></el-table-column>
        <el-table-column label="角色" prop="roleName" align="center"></el-table-column>
        <el-table-column label="认领单位" prop="unit" align="center"></el-table-column>
        <el-table-column label="最后登录时间" prop="loginTime" align="center"></el-table-column>
        <el-table-column
          v-if="$hasPerms('systemUser:state')"
          label="登录状态"
          align="center"
          width="160px"
        >
          <template v-slot="{ row }">
            <el-switch
              :width="60"
              v-model="row.notState"
              active-text="启用"
              inactive-text="禁用"
              :disabled="row.notFreeze"
              @change="stateChanged($event, row.id)"
            >
            </el-switch>
            <!-- <span>{{ row.notState }}</span> -->
          </template>
        </el-table-column>
        <el-table-column label="登录次数" prop="count" align="center" sortable></el-table-column>
        <el-table-column
          v-if="$hasPerms('systemUser:update') || $hasPerms('systemUser:freeze')"
          label="操作"
          prop="createTime"
          align="center"
        >
          <template v-slot="{ row }">
            <div class="dis_inle" v-if="$hasPerms('systemUser:update')">
              <span v-if="!row.notFreeze" class="edit" @click="editBtn(row.id)">编辑</span>
            </div>
            <div class="dis_inle" v-if="$hasPerms('systemUser:freeze')">
              <span v-if="row.notFreeze" class="frozen">已冻结</span>
              <span v-else class="frozen" @click="userNotFreeze(row.id)">冻结</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
   
    <!-- 新增弹窗 -->
    <!-- <el-dialog
      title="新增"
      :visible.sync="DialogVisible"
      width="527px"
      :close-on-click-modal="false"
    >
      <el-form label-width="100px" :model="adminUser" :rules="adminrules" ref="form">
        <el-form-item label="手机号:" prop="mobile">
          <el-input v-model="adminUser.mobile" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="真实姓名:" prop="userName">
          <el-input v-model="adminUser.userName" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="角色:" prop="roleId">
          <el-select v-model="roleId" placeholder="请选择角色" @change="roleSelected">
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用:" prop="notState">
          <el-radio v-model="adminUser.notState" :label="true">启用</el-radio>
          <el-radio v-model="adminUser.notState" :label="false">禁用</el-radio>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitForm()" :disabled="submitBtnDisabled"
          >确 定</el-button
        >
        <el-button @click="DialogVisible = false">取 消</el-button>
      </div>
    </el-dialog> -->
    <el-dialog
      title="新增"
      :visible.sync="DialogVisible"
      width="800px"
      :close-on-click-modal="false"
    >
      <el-form label-width="80px" :model="adminUser" :rules="adminrules" ref="form">
        <el-form-item label="姓名:" prop="userName">
          <el-input v-model="adminUser.userName" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="手机号:" prop="mobile">
          <el-input v-model="adminUser.mobile" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="角色:" prop="roleId">
          <el-select v-model="roleId" placeholder="请选择角色" @change="roleSelected">
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权限:" prop="" v-if="roleId == 1">
          <el-radio-group v-model="adminUser.unitId" @change="unitchange">
            <el-radio v-for="(el, index) in roleLists" :key="index" :label="el.id">{{
              el.unit
            }}</el-radio>
          </el-radio-group>
          <el-checkbox-group v-model="adminUser.unitIdList">
            <el-checkbox v-for="(el, index) in childList" :key="index" :label="el.id">{{
              el.unit
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitForm()" :disabled="submitBtnDisabled"
          >确 定</el-button
        >
        <el-button @click="DialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  userList,
  userDetail,
  addUser,
  deleteUser,
  editUser,
  modifyPassword,
  resetPassword,
  userNotState,
  userNotFreeze,
  roleListAll,
  getUnitTreeResult
} from '@/api/system.js'
import sysunit from '@/components/sysunit.vue'

const defaultQueryInfo = Object.freeze({
  orders: 0,
  userName: '',
  unit:'',
  pageNum: 1,
  pageSize: 10
})
const defaultAdminUser = Object.freeze({
  mobile: '',
  notState: true,
  roleId: 0,
  unitIdList: [],
  unitId: '',
  userName: ''
})
export default {
  name: 'User',
   components: { sysunit },
  data() {
    return {
      roleLists: [],
      childList: [],
      roleId: null, // 选中的角色id
      addBtnDisabled: false,
      submitBtnDisabled: false,
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      DialogVisible: false,
      adminUser: { ...defaultAdminUser },
      roleList: [],
      adminrules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: (rule, value, cb) => {
              if (/^1[3-9]\d{9}$/.test(value)) {
                cb()
              } else {
                cb(new Error('手机号格式错误'))
              }
            },
            trigger: 'blur'
          }
        ],
        userName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        roleId: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        notState: [{ required: true, message: '请设置登录状态', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.search()
    this.getUnitTreeResult()
  },
  methods: {
    async getUnitTreeResult() {
      const res = await getUnitTreeResult()
      if (res.resultCode == 200) {
        this.roleLists = res.data.unitTreeResultList
      }
    },
    unitchange(val) {
      this.roleLists.forEach((el) => {
        if (val == el.id) {
          if (el.companyUnitList) {
            this.childList = el.companyUnitList
            this.childList.forEach(o=>{
              this.adminUser.unitIdList.push(o.id)
            })
          }else{
            this.childList=[]
            this.adminUser.unitIdList=[]
          }
        }
      })
    },
    async search() {
      this.loading = true
      const res = await userList(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      } else {
        this.loading = false
        this.$message.warning('获取用户信息失败!')
      }
    },
    // 获取角色列表数据
    async roleListAll() {
      const res = await roleListAll()
      if (res.resultCode === 200) {
        this.roleList = res.data
      }
    },
    //新增用户
    addUser() {
      this.addBtnDisabled = true
      setTimeout(() => {
        this.addBtnDisabled = false
      }, 1000)
      this.roleId = ''
      this.DialogVisible = true
      this.roleListAll()

      this.adminUser = { ...defaultAdminUser }
    },
    //编辑
    editBtn(id) {
      this.childList=[]
      this.adminUser = { ...defaultAdminUser }
      this.userDetail(id)
      this.roleListAll()
      this.DialogVisible = true
    },
    // 用户详情
    async userDetail(id) {
      const res = await userDetail({ id })
      if (res.resultCode == 200) {
        this.adminUser = res.data

        this.roleId = this.adminUser.roleId

        this.$set(this.adminUser, 'unitIdList', [])

        if (this.roleId == 1) {
          this.$set(this.adminUser, 'unitId', this.adminUser.unitTreeResult.id)
          if (this.adminUser.unitTreeResult.companyUnitList) {
            let list = this.adminUser.unitTreeResult.companyUnitList
            list.forEach((el) => {
              this.adminUser.unitIdList.push(el.id)
            })
          }

          this.roleLists.forEach((el) => {
            if (this.adminUser.unitId == el.id) {
              if (el.companyUnitList) {
                this.childList = el.companyUnitList
              }
            }
          })
        }
      }
    },
    // 选中角色
    roleSelected(id) {
      this.adminUser.roleId = id
      this.roleList.forEach((el) => {
        if (id == el.id) {
          if (el.roleName == '系统管理员') {
            this.adminUser.unitIdList = [54]
          }
        }
      })

      // this.adminUser.roleId.push(id)
    },
    onChanges(firstVal){
      this.queryInfo.unit=firstVal
        this.search()
    },  
    //保存按钮
    submitForm() {
      this.submitBtnDisabled = true
      setTimeout(() => {
        this.submitBtnDisabled = false
      }, 1000)
      if (this.roleId == 1) {
        this.adminUser.unitIdList.unshift(this.adminUser.unitId)
      }
      if (this.roleId == 3) {
        this.adminUser.unitIdList=[54]
      }
      this.adminUser.account = this.adminUser.mobile
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.adminUser.id) {
            addUser(this.adminUser).then((response) => {
              if (response.resultCode == 200) {
                this.$message.success('新增成功')
                this.DialogVisible = false
                this.search()
              } else {
                this.$message.warning(response.message)
              }
            })
          } else {
            editUser(this.adminUser).then((response) => {
              if (response.resultCode == 200) {
                this.$message.success('编辑成功')
                this.DialogVisible = false
                this.search()
              } else {
                this.$message.warning(response.message)
              }
            })
          }
        }
      })
    },
    // 修改登录状态
    stateChanged(val, id) {
      // console.log(val)
      userNotState({ id })
      // .then(() => {
      //   this.search()
      // })
    },
    // 冻结用户
    userNotFreeze(id) {
      this.$confirm('确认是否冻结，冻结后不可撤回')
        .then(async () => {
          const res = await userNotFreeze({ id })
          if (res.resultCode == 200) {
            this.$message.success('冻结成功!')
            this.search()
          } else {
            this.$message.warning('冻结失败!')
          }
        })
        .catch(() => {})
    },
    // 排序
    sortChanged(val) {
      console.log(val)
      if (val.order === 'ascending') {
        this.queryInfo.orders = 1
        this.search()
      } else {
        this.queryInfo.orders = 2
        this.search()
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep  .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #333333;
  line-height: 44px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

::v-deep .el-radio-group {
  margin-top: 10px;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;
}
::v-deep .el-radio {
  width: 100px;
  color: #606266;
  font-weight: 500;
  line-height: 1;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  outline: 0;
  font-size: 14px;
  margin-right: 30px;
  margin-bottom: 10px;
}
::v-deep .el-radio__label {
  font-size: 15px;
  padding-left: 10px;
  font-weight: 550;
}
.user{
  background: #fff;
  padding: 20px;
  font-size: 16px !important;
}
.row {
  display: flex;
  align-items: center;
}
.dis_inle {
  display: inline-block;
}
.seach_box {
  margin-left: auto;
  .input-with-select {
    width: 400px;
  }
  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}
.office {
  display: inline-block;
  background: #edf4ff;
  border: 1px solid #559eff;
  color: #4e93fb;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 3px;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.in_table {
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  margin-top: 20px;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}
.edit {
  padding-left: 20px;
  color: #4e93fb;
  cursor: pointer;
}
.frozen {
  padding-left: 20px;
  color: #fd5469;
  cursor: pointer;
}

.new_page {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
//开关样式
.el-switch ::v-deep .el-switch__label--left {
  position: relative;
  left: 60px;
  color: #fff;
  z-index: -1;
}
.el-switch ::v-deep .el-switch__label--right {
  position: relative;
  right: 60px;
  color: #fff;
  z-index: -1;
}
.el-switch ::v-deep .el-switch__label--right.is-active {
  z-index: 999;
  color: #fff !important;
}
.el-switch ::v-deep .el-switch__label--left.is-active {
  z-index: 999;
  color: #959b9f !important;
}
::v-deep .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  display: none;
}
</style>