<template>
  <div>
    <div class="openClose">
      <div class="openInfo" :class="{ newClass: isShow }">
        <el-radio-group v-model="value" @change="changeVal">
          <el-radio-button label>不限</el-radio-button>
          <el-radio-button v-for="(item, index) in options" :key="index" :label="item.codeName"></el-radio-button>
        
        </el-radio-group>
      </div>
      <div class="openButton" @click="openItem()" v-if="options.length > 10">
        {{ openSpan }}
        <i :class="openicon"></i>
      </div>
    </div>
    <div class="children">
      <span v-if="value">子标签：</span>
      <el-checkbox-group v-model="checkboxVal" @change="changeText">
        <el-checkbox-button v-for="item in childrenArray" :key="item.id" :label="item.codeName">{{
            item.codeName
          }}</el-checkbox-button>
       
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import { getIndustryLabelLists } from '@/api/enterpriseCustomer.js'

export default {
  props: {
    typeCode: {
      // 查询的字典值
      type: String,
      default: ''
    },
    code: {
      // 选中的那一项的key
      type: String,
      default: "",
      require: true,
    },

    queryName: {
      // 需要给后端的字段
      type: String
    },
    name: {
      // 前端展示的字段
      type: String
    },
    tag: {
      type: String
    }
  },
  watch: {
    code: function (newVal) {
    
      if (!newVal) {
        this.getDicData();
        this.value =''
        this.childrenArray = [];
      }
    },
  },

  data() {
    return {
      openSpan: '查看全部',
      openicon: 'el-icon-arrow-down',
      openValue: true,
      options: [],
      value: '',
      checkboxVal: [],
      childrenArray: [],
      firstVal: '',
      isShow: false
    }
  },
  created() {
    this.getDicData()
  },
  methods: {
    async getDicData() {
    
      this.options = sessionStorage.getItem('industry')

      if (!this.options) {
        let { data: res } = await getIndustryLabelLists()

        res = JSON.parse(JSON.stringify(res).replace(/industryName/g, 'codeName'))

        sessionStorage.setItem('industry', JSON.stringify(res))
      }

      this.options = JSON.parse(sessionStorage.getItem('industry'))

     
    },
    changeText(val) {
      this.$emit('onChange', this.firstVal, val)
    },

    changeVal(val) {
      this.firstVal = val

      this.checkboxVal = []

      this.childrenArray = []

      if (val) {
        this.options.forEach((el) => {
          if (el.codeName === val) {
            this.childrenArray = el.childNode
          }
        })
      } else {
        this.childrenArray = []
      }

      this.$emit('onChange', this.firstVal)
    },
    // 标签打开
    openItem() {
      if (this.openValue) {
        this.isShow = true
        this.openSpan = '查看全部'
        this.openicon = 'el-icon-arrow-up'
        this.openValue = false
      } else {
        this.isShow = false
        this.openSpan = '查看全部'
        this.openValue = true
        this.openicon = 'el-icon-arrow-down'
      }
    }
    // 标签打开
    // openItem() {
    //   if (this.openValue) {
    //     this.isShow = true
    //     this.openSpan = '展开全部'
    //     this.openicon = 'el-icon-arrow-up'
    //     this.openValue = false
    //   } else {
    //     this.isShow = false
    //     this.openSpan = '展开全部'
    //     this.openValue = true
    //     this.openicon = 'el-icon-arrow-down'
    //   }
    // },
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-radio-button__inner {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    background: #fff;
    border: none;
    font-weight: 500;
    border-left: 0;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    position: relative;
    cursor: pointer;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 0;
}
::v-deep .el-checkbox-button__inner {
    display: inline-block;
    line-height: 1;
    font-weight: 500;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background: #f5f6f7;
    border: none;
    border-left: 0;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    position: relative;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 0;
}
::v-deep .el-checkbox-button:first-child .el-checkbox-button__inner {
    border-left: 1px solid #fff;
    border-radius: 4px ;
    box-shadow: none !important;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
    border-left: 1px solid #fff;
    border-radius: 4px ;
    box-shadow: none !important;
}
.openInfo {
  width: 90%;
  min-height: 20px;
  max-height: 32px;
  overflow: hidden;
  // transition: all 1s linear;
}
.newClass {
  width: 90%;
  max-height: 400px;
  //overflow: none;
  transition: all 1s linear;
}
/deep/.el-checkbox-group {
  background-color: #f5f6f7;
}
.children {
  margin-top: 5px;
  background-color: #f5f6f7;
  display: flex;
}
span {
  color: #606266 !important;
  padding-left: 20px;
  display: inline-block;
  min-width: 60px;
}
.openClose {
  display: flex;
  justify-content: space-between;
  .openButton {
    width: 10%;
    line-height: 30px;
    color: #1e9fff;
    cursor: pointer;
    text-align: center;
  }
}
// /deep/ .el-radio-button__inner,
// .el-radio-group {
//   padding: 5px;
// }
/deep/ .el-radio-group {
  
  padding-left: 0;
}
</style>
