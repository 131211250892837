<!-- 新增、编辑企业dialog对话框 -->
<template>
  <div class="addenterprise">
    <!-- 新增、编辑企业 -->
    <el-dialog
      :title="echo === 0 ? '新增企业' : '编辑企业'"
      :visible.sync="addDialogVisible"
      :before-close="addDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="企业头像:" prop="companyLogo">
          <uploadLogo
            :imageShow="form.companyLogo"
            @uploadOneImgShow="uploadOneImgShow"
          ></uploadLogo>
        </el-form-item>
        <el-form-item style="margin-top: 10px" label="企业简称:" prop="companyName">
          <div class="input_box">
            <el-input v-model="form.companyName" placeholder="请输入企业简称" />
          </div>
        </el-form-item>
        <el-form-item label="企业名称:" prop="companyFullName">
          <div class="input_box">
            <el-input v-model="form.companyFullName" placeholder="请输入企业名称" />
          </div>
        </el-form-item>
        <el-form-item label="所在地区:" prop="city">
          <div class="row">
            <CitySelectTwo
              ref="child"
              :country="form.country"
              :province="form.province"
              :city="form.city"
              :district="form.district"
              @searchProvince="searchProvinceAddEnterprise"
              @searchCity="searchCityAddEnterprise"
              @searchDistrict="searchAddDistrict"
            />
          </div>
        </el-form-item>
        <el-form-item label="所在行业:" prop="industry">
          <div>
            <div class="form_radio_group">
              <el-radio-group v-model="form.industry" @change="changeVal">
                <el-radio
                  style="width: 55px"
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.industryName"
                ></el-radio>
              </el-radio-group>
            </div>
            <div class="form_radio_group" v-if="form.industry == '工矿'">
              <el-radio-group v-model="form.segmentedIndustry">
                <el-radio
                  v-for="(item, index) in childoptions"
                  :key="index"
                  :label="item.industryName"
                ></el-radio>
              </el-radio-group>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="认领单位：" prop="unit">
          <el-select
            filterable
            style="width: 70%"
            reserve-keyword
            placeholder="请输入关键词"
            v-model="form.unit"
          >
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.unit"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="成立时间:" prop="establishedTime">
          <el-date-picker
            v-model="form.establishedTime"
            type="date"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd"
            prefix-icon="el-icon-date"
            class="picker"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="企业规模:" prop="">
          <div class="form_radio_group">
            <el-radio-group v-model="form.enterpriseScale">
              <el-radio label="L(大型)">大型</el-radio>
              <el-radio label="M(中型)">中型</el-radio>
              <el-radio label="S(小型)">小型</el-radio>
              <el-radio label="XS(微型)">微型</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="社保人数:" prop="">
          <div class="form_radio_group">
            <el-input  style="width: 70%" placeholder="请输入社保人数" v-model="form.insuredPersonsNumber"></el-input>
            <!-- <el-radio-group v-model="form.insuredPersonsNumber" @change="changeinsured">
              <el-radio label="0-1000">0-1000</el-radio>
              <el-radio label="1000以上">1000以上</el-radio>
              <el-radio label="不明确">不明确</el-radio> -->
              <!-- <el-radio :label="'没有'">没有</el-radio> -->
            <!-- </el-radio-group> -->
          </div>
          <!-- <div class="input_box" v-if="form.insuredPersons == '1' || form.insuredPersons == '2'">
            <el-input
              style="width: 200px"
              v-model="form.insuredPersonsNumber"
              placeholder="请输入社保人数"
            />
            人
          </div> -->
        </el-form-item>
        <el-form-item label="企业等级:" prop="">
          <div class="form_radio_group">
            <el-radio-group v-model="form.clientGrade">
              <el-radio label="A类客户">A类客户</el-radio>
              <el-radio label="B类客户">B类客户</el-radio>
              <el-radio label="C类客户">C类客户</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="法定代表人:" prop="">
          <div class="input_box">
            <el-input v-model="form.legalPerson" placeholder="请输入法人" />
          </div>
        </el-form-item>
        <el-form-item label="企业简介:" prop="">
          <div class="input_box">
            <el-input
              v-model="form.enterpriseIntro"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 6 }"
              placeholder="请输入企业简介"
            />
          </div>
        </el-form-item>
        <el-form-item label="企业标签:" v-if="echo === 1">
          <div class="row">
            <div class="tag_box" v-for="(item, index) in form.companyLabelList" :key="index">
              {{ item }}
            </div>
            <!-- <el-tag
              :key="tag"
              v-for="tag in tagList"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput"
              >+ 新增标签</el-button
            > -->
          </div>
        </el-form-item>
      </el-form>
      <div class="dialog_footer">
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="addDialogClosed">取 消</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import CitySelect from '@/components/CitySelect.vue'
import { getTwoLevelUnit } from '@/api/customerManager.js'
import { getIndustryVo } from '@/api/visiting.js'
import CitySelectTwo from '@/components/CitySelectTwo.vue'
import uploadLogo from './uploadLogo.vue'
import { listById, save, update } from '@/api/enterpriseCustomer.js'
const operatorId = sessionStorage.getItem('operatorId')
const defaultForm = Object.freeze({
  operatorId,
  companyLabelList: [], // 企业标签集合
  city: '贵阳市', //所在地区
  companyEmail: null, //公司邮箱
  companyFullName: null, //企业全称
  companyLogo: '', //企业logo
  companyName: null, //企业名称
  companyPhone: null, //公司电话
  country: '中国', //所在国家
  enterpriseAddress: null, //公司地址
  enterpriseIntro: null, //简介
  enterpriseScale: 'L(大型)', //企业规模
  enterpriseType: null, //企业类型
  establishedTime: null, //成立时间
  financingRounds: '未融资', //融资轮次
  insuredPersonsNumber: null, //参保人数
  legalPerson: null, //法人
  officialWebsite: null, //公司官网
  orgCompanyId: null, // 总台企业编号
  province: '贵州省', //所在省份
  district: '',
  insuredPersons: '',
  clientGrade: '',
  industry: '',
  segmentedIndustry: ''
})

export default {
  name: 'AddEnterprise',
  components: { CitySelectTwo, uploadLogo },
  props: {
    echo: {
      // 0是新增，1是编辑
      type: Number
    }
  },
  data() {
    return {
      companyList: [], //认领单位
      requstCount: 0, // 节流阀
      inputVisible: false, // 新增标签
      inputValue: '', // 标签输入内容
      tagList: [], // 标签列表
      id: null, // 企业id
      addDialogVisible: false, // 控制显示新增、编辑企业弹窗
      form: { ...defaultForm },
      options: [], //所属行业
      childoptions: [], //所属行业子集
      // 新增企业的表单验证规则
      rules: {
        insuredPersonsNumber: [{ required: true, message: '请选择选择社保人数', trigger: 'blur' }],
        enterpriseScale: [{ required: true, message: '请选择企业规模', trigger: 'change' }],
        companyFullName: [{ required: true, message: '请输入企业全称', trigger: 'blur' }],
        city: [{ required: true, message: '请输入所在地区', trigger: 'blur' }],
        // officialWebsite: [{ required: true, message: '请输入企业官网', trigger: 'blur' }],
        // enterpriseAddress: [{ required: true, message: '请输入企业地址', trigger: 'blur' }],
        industry: [{ required: true, message: '请选择所在行业', trigger: 'change' }],
        legalPerson: [{ required: true, message: '请输入法人', trigger: 'blur' }],
        enterpriseIntro: [{ required: true, message: '请输入企业简介', trigger: 'blur' }],
        // insuredPersonsNumber: [{ required: true, message: '请输入参保人数', trigger: 'blur' }],
        unit: [{ required: true, message: '请选择认领单位', trigger: 'change' }]
      }
    }
  },
  watch: {
    addDialogVisible(val) {
      if (val && this.id) {
        this.listById()
      }
    }
  },
  created() {
    this.getIndustryVo()
    this.remoteMethod('')
  },
  computed: {},
  methods: {
    //所属行业
    async getIndustryVo() {
      const res = await getIndustryVo()
      if (res.resultCode == 200) {
        this.options = res.data
      }
    },
    changeVal(val) {
      this.options.forEach((el) => {
        if (el.industryName == val) {
          // console.log(el);
          if (el.children) {
            this.childoptions = el.children
          } else {
            this.childoptions = []
            this.form.segmentedIndustry = ''
          }
        }
      })
    },
    async remoteMethod(str) {
      const query = {
        queryParam: str
      }
      const res = await getTwoLevelUnit(query)
      if (res.resultCode == 200) {
        this.companyList = res.data
      }
    },
    changeinsured() {
      if (this.form.insuredPersonsNumber == '0-1000') {
        this.form.clientGrade = 'B类客户'
      } else if (this.form.insuredPersonsNumber == '1000以上') {
        this.form.clientGrade = 'A类客户'
      } else {
        this.form.clientGrade = 'C类客户'
      }
    },
    // 重置节流阀
    resetRequstCount() {
      this.requstCount++
      setTimeout(() => {
        this.requstCount = 0
      }, 1500)
    },
    // 获取企业详情
    async listById() {
      const res = await listById({ id: this.id })
      if (res.resultCode === 200) {
        this.form = res.data
      } else {
        this.$message.warning('获取企业详情失败!')
      }
    },
    // 图片上传路径长地址  logo
    uploadOneImgShow(value) {
      this.form.companyLogo = value
    },
    //返回省  新增、编辑企业
    searchProvinceAddEnterprise(val) {
      this.form.province = val
    },
    //返回城市  新增、编辑企业
    searchCityAddEnterprise(val) {
      this.form.city = val
    },
    searchAddDistrict(val) {
      this.form.district = val
    },
    // // 删除标签
    // handleClose(val) {
    //   console.log(val)
    // },
    // // 新增标签
    // showInput() {
    //   this.inputVisible = true
    //   this.$nextTick((_) => {
    //     this.$refs.saveTagInput.$refs.input.focus()
    //   })
    // },
    // // 保存标签
    // handleInputConfirm() {
    //   let inputValue = this.inputValue
    //   if (inputValue) {
    //     this.tagList.push(inputValue)
    //   }
    //   this.inputVisible = false
    //   this.inputValue = ''
    // },
    //保存按钮  新增、编辑企业
    save() {
      if (this.requstCount === 0) {
        this.$refs.formRef.validate(async (valid) => {
          if (valid) {
            const fn = this.echo === 0 ? save : update
            const txt = this.echo === 0 ? '新增' : '编辑'
            const res = await fn(this.form)
            if (res.resultCode === 200) {
              this.$message.success(txt + '成功!')
              this.$refs.child.clear()
              this.form = { ...defaultForm }
              this.$emit('search')
              this.addDialogVisible = false
              if (this.echo !== 0) {
                this.id = null
              }
            } else {
              this.$message.warning(res.message)
            }
          }
        })
      }
    },
    //新增、编辑企业弹窗关闭
    addDialogClosed() {
      this.addDialogVisible = false
      this.id = null
      this.$refs.child.clear()
      this.form = { ...defaultForm }
    }
  }
}
</script>
<style lang="less" scoped>
.el-form-item .input_box {
  width: 70%;
}
::v-deep .el-form-item {
  margin-bottom: 20px !important;
}
::v-deep .form_radio_group .el-radio {
  margin: 15px 0px 5px 0px;
  color: #606266;
  font-weight: 500;
  line-height: 1;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  outline: 0;
  font-size: 14px;
  margin-right: 30px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
::v-deep .el-form-item__content {
  line-height: 30px;
  position: relative;
  font-size: 14px;
}
.row .tag_box {
  height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background-color: #efefef;
  margin-right: 10px;
}
</style>
