<template>
  <div class="table_padding">
    <el-table
      v-loading="loading"
      element-loading-text="加载中..."
      element-loading-spinner="el-icon-loading"
      :data="listData"
      ref="multipleTable"
      stripe
      style="width: 100%"
      class="in_table"
      :row-key="getRowKey"
      @selection-change="handleSelectionChange"
      @sort-change="changeAdviserOrders"
      :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
    >
      <el-table-column type="selection" width="60" align="center"> </el-table-column>
      <el-table-column v-if="type != 1 && unitId" prop="time" label="走访时间" align="center">
      </el-table-column>

      <el-table-column label="认领单位" align="center" min-width="60">
        <template v-slot="{ row }">
          <span>{{ row.unit }}</span>
        </template>
      </el-table-column>
      <el-table-column label="客户经理数" sortable prop="customerNum" align="center" min-width="60">
        <template v-slot="{ row }">
          <span>{{ row.customerNum }}</span>
        </template>
      </el-table-column>
      <el-table-column
       v-if="typeName==2||typeName==0"
        prop="visitNumTwo"
        min-width="160"
        label="中小企业数字化水平评测指标（2024年版）数"
        align="center"
        sortable
      >
      </el-table-column>
      <el-table-column
      v-if="typeName==1||typeName==0"
        prop="visitNumOne"
        min-width="100"
        label="贵阳电信线下走访记录工具数"
        align="center"
        sortable
      >
      </el-table-column>
     
      <el-table-column
        label="达标次数"
        prop="companyFullName"
        align="center"
        min-width="80"
      >
        <template v-slot="{ row }">
          <!-- <span>{{ row.visitNum }}</span> -->
          <span>{{ row.eligibleVisitNum }}</span>
        </template>
      </el-table-column>

      <el-table-column label="走访是否达标(人均2次/天）" prop="" align="center" min-width="60">
        <template v-slot="{ row }">
          <span class="red_color" v-if="row.reachStandard=='未达标'" @click="standard(row)">未达标</span>
          <span v-else class="blue_color" @click="standard(row)">达标</span>
        </template></el-table-column
      >
    </el-table>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="title"
      :visible.sync="setManagerDialogVisible"
      @closed="setManagerDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <div class="dialog_box">
        <div class="dialog_box_top">
          <div class="dialog_box_top_left">
            <span>未达标数：</span>
            <span>{{ customer.noEligibleVisitNum }}</span>
          </div>
          <div class="dialog_box_top_right">
            <span>达标数：</span>
            <span>{{ customer.eligibleVisitNum }}</span>
          </div>
        </div>
        <div class="border_left"></div>
        <div class="table">
          <el-table
            ref="managerTable"
            :data="managerList"
            @sort-change="sortchangeOrders"
            style="width: 100%; min-height: 400px"
            :header-cell-style="{ background: '#F8F9FB' }"
          >
            <el-table-column type="index" :index="indexMethod" label="序号" align="center">
            </el-table-column>
            <el-table-column prop="customerName" align="center" label="客户经理姓名">
            </el-table-column>
            <el-table-column label="走访企业数" prop="visitCompanyNum" sortable align="center">
              <template v-slot="{ row }">
                <span>{{ row.visitCompanyNum }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="visitNum" sortable label="走访次数" align="center">
            </el-table-column>
            <el-table-column prop="companyCount" label="走访是否达标(人均2次/天)" align="center">
              <template v-slot="{ row }">
                <span class="red_color" v-if="!row.reachStandard">未达标</span>
                <span v-else class="blue_color">达标</span>
              </template>
            </el-table-column>
            <el-table-column prop="visitTime" label="最近走访时间" align="center" sortable>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="meetInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="meetInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="managerTableTotal"
          @size-change="handleSizeChangeManager"
          @current-change="handleCurrentChangeManager"
        />
      </div>
    </el-dialog>
  </div>
</template>
  
    <script>
import {
  selectVisitRecordStatistics,
  selectVisitRecordDateStatistics,
  selectVisitRCustomer,
  selectQuestionnaire,
  getVisitRecordCustomerNum
} from '@/api/visiting.js'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  time: '', //头部；时间
  type: 1, //1=日，2=周，3=月，4=年
  reachStandard: '',
  questionnaireId: '',
  weekNum: null,
  orders: 1,
  unitId: ''
})
export default {
  props: {
    type: {
      type: Number
    },
    reachStandard: {
      type: String
    },
    time: {
      type: String
    },
    unitId: {
      type: String
    },
    questionnaireId: {
      type: String
    }
  },
  name: 'Diagnosisrecord',
  data() {
    return {
      listData: [],
      managerList: [],
      title: '',
      ids: [],
      // 遮罩层
      loading: true,
      meetInfo: {
        orderKey: 0,
        orders: 0,
        pageNum: 1,
        pageSize: 10,
        time: '', //头部；时间
        type: 1, //1=日，2=周，3=月，4=年
        reachStandard: '',
        questionnaireId: '',
        weekNum: null,
        unitId: ''
      },
      typeName:0,
      questList:[],
      customer: {},
      total: 0,
      queryInfo: { ...defaultQueryInfo },
      managerTableTotal: 0,
      setManagerDialogVisible: false
    }
  },
  created() {
    // this.queryInfo.time = this.getTodayDate()
    this.queryInfo.time = this.time
    this.queryInfo.type = this.type
    this.queryInfo.unitId = this.unitId
    this.queryInfo.reachStandard = this.reachStandard
    this.queryInfo.questionnaireId = this.questionnaireId
    // this.search()
    if (this.type == 2) {
      let time = this.getTodayDate()
      this.queryInfo.weekNum = this.getWeekNumber(new Date(time))
    }
    if (this.unitId && this.type != 1) {
      this.selectVisit()
    } else {
      this.search()
    }
    // this.search()
  },
  watch: {
    type(val) {
      this.queryInfo.type = val
      this.queryInfo.pageNum = 1
      this.queryInfo.time = this.getTodayDate()
      if (val == 2) {
        this.queryInfo.weekNum = this.getWeekNumber(new Date(this.queryInfo.time))
      } else if (val == 3) {
        const today = new Date(this.queryInfo.time)
        const year = today.getFullYear()
        const month = today.getMonth() + 1
        this.queryInfo.time = `${year}-${month}`
      } else if (val == 4) {
        const today = new Date(this.queryInfo.time)
        const year = today.getFullYear()

        this.queryInfo.time = `${year}`
      } else {
        this.queryInfo.time = this.time
      }
      if (this.unitId && val != 1) {
        this.selectVisit()
      } else {
        this.search()
      }
    },
    time(val) {
      this.queryInfo.time = val
      if (this.type == 2) {
        this.queryInfo.weekNum = this.getWeekNumber(new Date(this.queryInfo.time))
      }
      if (this.unitId && this.type != 1) {
        this.selectVisit()
      } else {
        this.search()
      }
    },
    reachStandard(val) {
      this.queryInfo.reachStandard = val
      if (this.unitId && this.type != 1) {
        this.selectVisit()
      } else {
        this.search()
      }
    },
    questionnaireId(val) {
      this.queryInfo.questionnaireId = val
      if (this.unitId && this.type != 1) {
        this.selectVisit()
      } else {
        this.search()
      }
      this.selectQuestionnaire()
    },
    unitId(val) {
      this.queryInfo.unitId = val
      if (val && this.type != 1) {
        this.selectVisit()
      } else {
        this.search()
      }
    }
  },

  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      const res = await selectVisitRecordStatistics(this.queryInfo)
      if (res.resultCode == 200) {
        this.listData = res.data.list
        this.loading = false
        this.total = res.data.total
      } else {
        this.loading = false
      }
    },
    async selectVisit() {
      this.loading = true
      const res = await selectVisitRecordDateStatistics(this.queryInfo)
      if (res.resultCode == 200) {
        this.listData = res.data.list
        this.loading = false
        this.total = res.data.total
      } else {
        this.loading = false
      }
    },
    async selectQuestionnaire() {
      const res = await selectQuestionnaire()
      if (res.resultCode == 200) {
        this.questList = res.data
        this.questList.forEach(el=>{
          if(el.id==this.queryInfo.questionnaireId){
            if(el.questionnaireName=='贵阳电信线下走访记录工具'){
              this.typeName=1
            }else{
              this.typeName=2
            }
          }else if(!this.queryInfo.questionnaireId) {
            this.typeName=0
          }
        })
      }
    },
    //获取当前年月日
    getTodayDate() {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1 // 月份是从0开始的，所以需要+1
      const day = today.getDate()
      return `${year}-${month}-${day}`
    },
    // 计算第几周
    getWeekNumber(date) {
      // 获取一周的第一天（周日）的日期
      const firstDay =
        date.getDay() === 0
          ? date
          : new Date(date.getTime() - ((date.getDay() || 7) - 1) * 86400000)

      // 获取一年第一周的第一天（周日）的日期
      const firstWeekFirstDay = new Date(firstDay.getFullYear(), 0, 1)

      // 如果第一周的第一天不是周日，则将其调整为周日
      if (firstWeekFirstDay.getDay() !== 0) {
        firstWeekFirstDay.setDate(firstWeekFirstDay.getDate() - firstWeekFirstDay.getDay() + 1)
      }

      // 计算偏移量
      const offset = Math.floor((firstDay.getTime() - firstWeekFirstDay.getTime()) / (7 * 86400000))

      // 返回周数（可能为0，表示前一年的最后一周）
      return offset
    },
    getRowKey(row) {
      return row.id
    },
    //多选
    handleSelectionChange(selection) {
      
      this.ids = selection.map((item) => item.id)
      this.$emit('getids', this.ids)
    },
    async standard(item) {
      this.title = item.unit + '走访次数详情'
      this.meetInfo.reachStandard = this.queryInfo.reachStandard
      this.meetInfo.weekNum = this.queryInfo.weekNum
      this.meetInfo.unitId = item.id
      if (this.unitId && this.type != 1) {
        this.meetInfo.detailTime = ''
        this.meetInfo.detailTime = item.time
      } else {
        this.meetInfo.detailTime = ''
      }
      if (this.type == 4 && this.unitId) {
        this.meetInfo.detailTime = this.meetInfo.detailTime + '-01'
      }

      this.meetInfo.type = this.queryInfo.type
      this.meetInfo.time = this.queryInfo.time
      this.getVisitRecordCustomerNum()
      this.selectVisitRCustomer()
      this.setManagerDialogVisible = true
    },
    async selectVisitRCustomer() {
      const res = await selectVisitRCustomer(this.meetInfo)
      if (res.resultCode == 200) {
        this.managerList = res.data.list
        this.managerTableTotal = res.data.total
      }
    },
    async getVisitRecordCustomerNum() {
      const res = await getVisitRecordCustomerNum(this.meetInfo)
      if (res.resultCode == 200) {
        this.customer = res.data
      }
    },
    setManagerDialogClosed() {
      this.setManagerDialogVisible = false
    },
    indexMethod(index) {
      // (currentPage - 1) * pageSize + index + 1
      // index 是当前行的索引（从0开始）
      // 计算当前行的序号
      return (this.meetInfo.pageNum - 1) * this.meetInfo.pageSize + index + 1
    },
    //

    
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      if (this.unitId && this.type != 1) {
        this.selectVisit()
      } else {
        this.search()
      }

      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      if (this.unitId && this.type != 1) {
        this.selectVisit()
      } else {
        this.search()
      }

      // console.log(`当前页: ${val}`)
    },
    handleSizeChangeManager(val) {
      this.meetInfo.pageSize = val
      this.selectVisitRCustomer()
    },
    handleCurrentChangeManager(val) {
      this.meetInfo.pageSize = val
      this.selectVisitRCustomer()
    },
    changeAdviserOrders(val) {
      if (val.prop == 'customerNum') {
        if (val.order == 'ascending') {
          this.queryInfo.orders == 2
        } else {
          this.queryInfo.orders == 1
        }
      }
      this.search()
    },
    sortchangeOrders(val) {
      if (val.prop == 'visitCompanyNum') {
        if (val.order == 'ascending') {
          this.meetInfo.orderKey = 2
        } else {
          this.meetInfo.orderKey = 1
        }
      }
      if (val.prop == 'visitNum') {
        if (val.order == 'ascending') {
          this.meetInfo.orderKey = 4
        } else {
          this.meetInfo.orderKey = 3
        }
      }
      if (val.prop == 'visitTime') {
        if (val.order == 'ascending') {
          this.meetInfo.orderKey = 6
        } else {
          this.meetInfo.orderKey = 5
        }
      }
      this.selectVisitRCustomer()
    }
  }
}
</script>
  
    <style lang="less" scoped>
.table_padding {
  padding: 10px 20px;
}
.in_table {
  width: 100%;
  margin-top: 20px;
}
.red_color {
  color: #ff1313;
  cursor: pointer;
}
.blue_color {
  color: #ff7f0f;
  cursor: pointer;
}
.dialog_box {
  .dialog_box_top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .dialog_box_top_left {
      color: #ff1313;
      font-size: 16px;
      margin-right: 30px;
    }
    .dialog_box_top_right {
      color: #ff7f0f;
      font-size: 16px;
    }
  }
  .border_left {
    width: 3px;
    height: 20px;
    background: #448aff;
    position: absolute;
    top: 22px;
    left: 1px;
  }
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
.new_page {
  margin: 10px auto;
  width: 100%;
  text-align: center;
}
</style>
  