<template>
  <div class="case_info">
    <el-tabs v-model="activeName">
      <el-tab-pane label="潜在线索详情" name="first">
        <detailscase v-if="caseInfo" :caseInfo="caseInfo||{}"></detailscase>
      </el-tab-pane>

      <el-tab-pane :label="`推送客户经理数(${managernum})`" name="second">
        <PushManager v-if="caseInfo" :customerIdSet="caseInfo.customerIdSet"></PushManager>
      </el-tab-pane>
      <el-tab-pane :label="`推送企业数(${companynum})`" name="third">
        <Pushenterprises v-if="caseInfo" :companyList="caseInfo.companyList"></Pushenterprises>
      </el-tab-pane>
    </el-tabs>
    <div class="card_footer" v-if="activeName!='first'">
      <el-button type="primary" @click="back">返回上一级</el-button>
    </div>
  </div>
</template>
    
    <script>
import detailscase from './details.vue'
import PushManager from './PushManager.vue'
import Pushenterprises from './Pushenterprises.vue'
import { listById } from '@/api/plan.js'

export default {
  name: 'caseinfo',

  components: { detailscase, PushManager, Pushenterprises },
  data() {
    return {
      activeName: 'first',
      caseInfo:null
    }
  },
  //监听属性 类似于data概念
  computed: {
    id() {
      return this.$route.query.id
    },
    managernum() {
      return this.$route.query.managernum
    },
    companynum() {
      return this.$route.query.companynum
    },
    tabindex(){
      return this.$route.query.tabindex
    }
  },
  created() {
    if(this.tabindex){
      this.activeName=this.tabindex
     
    }
    this.search()
  },
 
  methods: {
      // 点击详情
      async search() {
      const res = await listById({ businessId: this.id })
      if (res.resultCode === 200) {
        let info=JSON.parse(res.data)
        this.caseInfo =info.data
       console.log( this.caseInfo);
       
      }
    },
      // 返回
      back() {
      this.$router.go(-1)
    },
  }
}
</script>
    
<style lang="less" scoped>
::v-deep .el-tabs__item.is-active {
  color: #448aff !important;
}
::v-deep .el-tabs__item {
  padding: 0 1.25rem;
  height: 2.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 2.5rem;
  display: inline-block;
  list-style: none;
  font-size: 17px;
  font-weight: 500;
  color: #333333;
  position: relative;
}
.case_info {
  padding: 10px 20px 0px 20px;
  background: #fff;
  .card_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    margin-top: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-top: #f4f4f4 1px solid;
  }
}
</style>
    