<!-- 经理详情 -->
<template>
  <div class="add_customer_manager">
    <div class="top_card">
      <div class="img_box">
        <img :src="form.customerHead" alt="" />
      </div>
      <div class="info_box">
        <div class="manager_name">{{ form.customerName }}</div>
        <div class="row">
          <div class="icon_box">
            <i class="iconfont icon-jr-icon-job color1"></i>
          </div>
          <span>职位：{{ form.customerPosition }}</span>
          <span>|</span>
          <span>{{ form.sex === 0 ? '男' : '女' }}</span>
          <div class="icon_box">
            <i v-if="form.sex === 1" class="iconfont icon-jr-icon-Woman color4"></i>
            <i v-else class="iconfont icon-jr-icon-Man color5"></i>
          </div>
        </div>
        <div class="row">
          <div class="icon_box">
            <i class="iconfont icon-jr-icon-area color1"></i>
          </div>
          <div class="txt_row">
            <span>所在区域：</span>
            <!-- <span v-if="form.provinces">{{ form.provinces + '-' }}</span> -->
            <span v-if="form.city">{{ form.city + '-' }}</span>
            <span v-if="form.district">{{ form.district }}</span>
          </div>
          <div class="icon_box">
            <i class="iconfont icon-jr-icon-phone color1"></i>
          </div>
          <span>手机号：{{ form.customerPhone }}</span>
        </div>
      </div>
      <div class="btn_list">
        <el-button @click="edit" plain icon="el-icon-edit-outline">修改信息</el-button>
      </div>
    </div>
    <div class="tabs">
      <div class="item" :class="activeName === 1 ? 'active_item' : ''" @click="toRelateEnterprise">
        关联企业
      </div>
      <!-- <div class="item" :class="activeName === 2 ? 'active_item' : ''" @click="toLearningCenter">
        学习中心
      </div> -->
    </div>
    <div class="card">
      <relateEnterprise v-if="activeName === 1" :id="id" />
      <learningCenter v-if="false" />
    </div>
    <!-- 编辑客户经理 -->
    <el-dialog
      title="编辑信息"
      :visible.sync="addDialogVisible"
      :before-close="addDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <el-form ref="formRef" :rules="rules" :model="form" label-width="100px">
        <el-form-item label="头像：">
          <div>
            <img-big-upload
              :imageShow.sync="form.customerHead"
              :addTitleShow="false"
              @removeImg="removeImgImage"
              @uploadOneImgShow="uploadOneImgShowImage"
            />
          </div>
        </el-form-item>
        <el-form-item label="姓名：" prop="customerName">
          <div class="input_box">
            <el-input v-model="form.customerName"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="当前单位：" prop="currentUnit">
          <el-select
                filterable
                remote
                style="width: 300px;"
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                @change="$forceUpdate()"
                v-model="form.currentUnit"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.id"
                  :label="item.unit"
                  :value="item.id"
                >
                </el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="职位：" prop="customerPosition">
          <div class="input_box">
            <el-input v-model="form.customerPosition"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="性别：">
          <el-radio-group v-model="form.sex">
            <el-radio v-for="item in sexList" :key="item.id" :label="item.id">{{
              item.label
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号：" prop="customerPhone">
          <div class="input_box">
            <el-input v-model="form.customerPhone"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="所在区域：" prop="city">
          <city-select
            ref="child"
            :province="form.provinces"
            :city="form.city"
            :district="form.district"
            @searchProvince="searchAddProvince"
            @searchCity="searchAddCity"
            @searchDistrict="searchAddDistrict"
          />
        </el-form-item>
      </el-form>
      <div class="dialog_footer">
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="save" :disabled="saveBtnDisable">保存</el-button>
          <el-button @click="addDialogClosed">取 消</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listById, update,getTwoLevelUnit } from '@/api/customerManager.js'
import CitySelect from '@/components/CitySelect.vue'
import learningCenter from './learningCenter.vue'
import relateEnterprise from './relateEnterprise.vue'

// const operatorId = sessionStorage.getItem('operatorId')
const defaultForm = Object.freeze({
  currentUnit: '', // 单位
  city: '', // 城市
  customerHead: '', // 头像
  customerName: '', // 名字
  customerPhone: '', // 电话
  customerPosition: '', // 职位
  district: '', // 区/县
  notFreeze: true, // 是否冻结(默认0 0->未冻结;1->已冻结)
  provinces: '', // 省
  sex: 0 // 性别(默认0 0->男;1->女)
})
export default {
  name: 'AddCustomerManager',
  components: { CitySelect, learningCenter, relateEnterprise },
  data() {
    return {
      saveBtnDisable: false, // 禁用保存按钮
      id: null, //用户id
      activeName: 1, //激活的tabs
      form: { ...defaultForm }, // 表单
      addDialogVisible: false, //新增客户经理
      companyList:[],
      rules: {
        customerName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        currentUnit: [
          { required: true, message: '请输入所处单位', trigger: 'blur' }
          // { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        customerPosition: [
          { required: true, message: '请输入职位', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        customerPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: (rule, value, cb) => {
              if (/^1[3-9]\d{9}$/.test(value)) {
                cb()
              } else {
                cb(new Error('手机号格式错误'))
              }
            },
            trigger: 'blur'
          }
        ],
        city: [{ required: true, min: 2, message: '请选择所在区域', trigger: 'blur' }]
      },

      //性别列表
      sexList: [
        {
          id: 0,
          label: '男'
        },
        {
          id: 1,
          label: '女'
        }
      ]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = Number(this.$route.query.id)
      this.activeName = Number(this.$route.query.val)
    }
    this.search()
  },
  methods: {
    async search() {
      const res = await listById({ id: this.id })
      if (res.resultCode === 200) {
        this.form = res.data[0]
        if(this.form.currentUnit){
          this.form.currentUnit=Number(this.form.currentUnit)
        }
        this.remoteMethod(this.form.unit)
      } else {
        this.$message.warning('获取客户经理信息失败!')
      }
    },
    async remoteMethod(str) {
      const query = {
      
        queryParam: str,
      };
      const res = await getTwoLevelUnit(query);
      if (res.resultCode == 200) {
        this.companyList = res.data;
      }
    },
    // 打开编辑客户经理信息弹窗
    async edit() {
      this.addDialogVisible = true
    },

    //删除头像
    removeImgImage(val) {
      this.form.customerHead = ''
    },
    //添加头像
    uploadOneImgShowImage(val) {
      this.form.customerHead = val
    },
    //返回省 弹窗
    searchAddProvince(val) {
      this.form.provinces = val
    },
    //返回城市 弹窗
    searchAddCity(val) {
      this.form.city = val
    },
    //返回区/县 弹窗
    searchAddDistrict(val) {
      this.form.district = val
    },
    //保存按钮
    async save() {
      this.saveBtnDisable = true
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const res = await update(this.form)
          if (res.resultCode === 200) {
            this.addDialogVisible = false
            this.saveBtnDisable = false
            this.$message.success('编辑成功!')
            this.search()
          } else {
            this.$message.warning('编辑失败!')
          }
        } else {
          this.$message.warning('请填写必要的信息!')
          this.saveBtnDisable = false
        }
      })
    },
    //编辑客户经理弹窗关闭
    addDialogClosed() {
      this.saveBtnDisable = false
      this.addDialogVisible = false
      this.search()
    },

    //关联企业
    toRelateEnterprise() {
      this.activeName = 1
    },
    //学习中心
    toLearningCenter() {
      this.activeName = 2
    }
  }
}
</script>

<style lang="less" scoped>
.input_box {
  width: 300px;
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .icon_box {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    img {
      width: 16px;
      height: 16px;
    }
  }
}
.btn_list {
  margin-left: auto;
  margin-right: 20px;
}
.add_customer_manager {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
}
.top_card {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  .img_box {
    width: 64px;
    height: 64px;
    margin-right: 10px;
    img {
      width: 64px;
      height: 64px;
    }
  }
  .info_box {
    display: flex;
    justify-content: center;
    flex-flow: column;
    .manager_name {
      font-weight: bold;
      font-size: 18px;
      color: #000;
      margin-bottom: 10px;
    }
    .row span {
      font-size: 14px;
      margin-right: 10px;
    }
    .row .txt_row {
      margin-right: 20px;
      span {
        margin-right: 0;
      }
    }
  }
  .btn_list ::v-deep .el-button {
    background: #fff;
    border: #4e93fb 1px solid;
    color: #4e93fb;
  }
  .el-button:hover {
    opacity: 0.7;
  }
}
.tabs {
  display: flex;
  width: 100%;
  padding: 10px 0;
  background-color: #fafafa;
  .item {
    cursor: pointer;
    text-align: center;
    padding: 10px 20px;
    font-size: 16px;
    color: #1f2d3d;
    position: relative;
  }
  .active_item:after {
    content: '';
    position: absolute;
    left: 36px;
    top: auto;
    bottom: 0;
    right: 0;
    height: 3px;
    width: 32px;
    border-radius: 8px;
    background-color: #4e93fb;
  }
}
.card {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 20px 30px 10px 30px;
  box-sizing: border-box;
  background-color: #fff;
}

.color1 {
  color: #4e93fb;
}
.color4 {
  color: #fd89ff;
}
.color5 {
  color: #0062ff;
}
</style>
