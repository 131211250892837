<template>
  <div>
    <div class="openClose">
      <div class="openInfo" :class="{ newClass: isShow }">
        <el-radio-group v-model="value" @change="changeVal">
          <el-radio-button label>不限</el-radio-button>
          <el-radio-button
            v-for="(item, index) in options"
            :key="index"
            :label="item.codeName"
          ></el-radio-button>
        </el-radio-group>
      </div>
      <div class="openButton" @click="openItem()" v-if="options.length > 10">
        {{ openSpan }}
        <i :class="openicon"></i>
      </div>
    </div>
    <div class="children" v-if="childrenArray.length">
      <!-- <span v-if="value">子标签：</span> -->
      <el-radio-group v-model="checkboxVal" @change="changeText">
        <el-radio-button v-for="item in childrenArray" :key="item.id" :label="item.codeName">{{
          item.codeName
        }}</el-radio-button>
      </el-radio-group>
    </div>
  </div>
</template>
  
  <script>
import { getUnitTreeResult } from '@/api/system.js'

export default {
  props: {
    typeCode: {
      // 查询的字典值
      type: String,
      default: ''
    },
    code: {
      // 选中的那一项的key
      type: String,
      default: '',
      require: true
    },

    queryName: {
      // 需要给后端的字段
      type: String
    },
    name: {
      // 前端展示的字段
      type: String
    },
    tag: {
      type: String
    }
  },
  watch: {
    code: function (newVal) {
      if (!newVal) {
        this.getDicData()
        this.value = ''
        this.childrenArray = []
      }
    }
  },

  data() {
    return {
      openSpan: '查看全部',
      openicon: 'el-icon-arrow-down',
      openValue: true,
      options: [],
      value: '',
      checkboxVal:'',
      childrenArray: [],
      firstVal: '',
      isShow: false
    }
  },
  created() {
    this.getDicData()
  },
  methods: {
    async getDicData() {
      this.options = sessionStorage.getItem('unit')

      if (!this.options) {
        let { data: res } = await getUnitTreeResult()

        res = JSON.parse(JSON.stringify(res.unitTreeResultList).replace(/unit/g, 'codeName'))

        sessionStorage.setItem('unit', JSON.stringify(res))
      }

      this.options = JSON.parse(sessionStorage.getItem('unit'))
    },
    changeText(val) {
      this.$emit('onChange',  val)
    },

    changeVal(val) {
      this.firstVal = val

      this.checkboxVal = []

      this.childrenArray = []

      if (val) {
        this.options.forEach((el) => {
          if (el.codeName === val) {
           
                if( el.companyUnitList){
                    this.childrenArray = el.companyUnitList
                }
          
          }
        })
      } else {
        this.childrenArray = []
      }

      this.$emit('onChange', this.firstVal)
    },
    // 标签打开
    openItem() {
      if (this.openValue) {
        this.isShow = true
        this.openSpan = '查看全部'
        this.openicon = 'el-icon-arrow-up'
        this.openValue = false
      } else {
        this.isShow = false
        this.openSpan = '查看全部'
        this.openValue = true
        this.openicon = 'el-icon-arrow-down'
      }
    }
    // 标签打开
    // openItem() {
    //   if (this.openValue) {
    //     this.isShow = true
    //     this.openSpan = '展开全部'
    //     this.openicon = 'el-icon-arrow-up'
    //     this.openValue = false
    //   } else {
    //     this.isShow = false
    //     this.openSpan = '展开全部'
    //     this.openValue = true
    //     this.openicon = 'el-icon-arrow-down'
    //   }
    // },
  }
}
</script>
  
  <style lang="less" scoped>
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: none;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 0;
}

::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px;
  box-shadow: none !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #448aff;
  border-radius: 4px;
}
.openInfo {
  width: 90%;
  min-height: 20px;
  max-height: 42px;
  overflow: hidden;
  // transition: all 1s linear;
}
.newClass {
  width: 90%;
  max-height: 400px;
  //overflow: none;
  transition: all 1s linear;
}
/deep/.el-checkbox-group {
  background-color: #f5f6f7;
}
.children {
  margin-top: 5px;
  margin-left: 80px;
  background-color: #f5f6f7;
  display: flex;
  padding:0px 10px 10px 10px;
}
span {
  color: #606266 !important;
  padding-left: 20px;
  display: inline-block;
  min-width: 60px;
}
.openClose {
  display: flex;
  justify-content: space-between;
  .openButton {
    width: 10%;
    line-height: 50px;
    color: #1e9fff;
    cursor: pointer;
    text-align: center;
  }
}
// /deep/ .el-radio-button__inner,
// .el-radio-group {
//   padding: 5px;
// }
/deep/ .el-radio-group {
  padding-left: 0;
}
</style>
  