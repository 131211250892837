<template>
  <div class="info_box">
    <div class="top_card">
      <el-form>
        <el-form-item label="认领单位：">
          <twoleventag @onChange="onChanges" />
        </el-form-item>
        <el-form-item label="所属行业：">
          <div class="flex_industry">
            <el-radio-group v-model="queryInfo.industry" @change="changeVal">
              <el-radio-button label>不限</el-radio-button>
              <el-radio-button
                v-for="(item, index) in options"
                :key="index"
                :label="item.industryName"
              ></el-radio-button>
            </el-radio-group>
            <div class="segmentedIndustry" v-if="queryInfo.industry == '工矿'">
              <span>子集：</span>
              <el-radio-group @change="search" v-model="queryInfo.segmentedIndustry">
                <!-- <el-radio-button label>不限</el-radio-button> -->
                <el-radio-button
                  v-for="(item, index) in childoptions"
                  :key="index"
                  :label="item.industryName"
                ></el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="所在地区：">
          <city-select
            ref="child"
            :province="queryInfo.provinces"
            :city="queryInfo.city"
            :district="queryInfo.district"
            @searchCity="searchCity"
            @searchDistrict="searchDistrict"
          />
        </el-form-item>

        <div class="row">
          <div class="row_flex">
            <div class="center_total">
              <span>共</span>
              <span class="color1">{{ total }}</span>
              <span>家企业</span>
            </div>
            <!-- <div class="sort">
              <span @click="handleSorts(sortVals)">按案例匹配度</span>
              <div class="sort_icon">
                <div
                  class="triangle_top_box"
                  :class="isActives === 'up' ? 'top_active' : ''"
                  @click="handleSorts('up')"
                ></div>
                <div
                  class="triangle_bottom_box"
                  :class="isActives === 'down' ? 'bottom_active' : ''"
                  @click="handleSorts('down')"
                ></div>
              </div>
            </div> -->
            <div class="sort">
              <span @click="handleSort(sortVal)">按推送时间</span>
              <div class="sort_icon">
                <div
                  class="triangle_top_box"
                  :class="isActive === 'up' ? 'top_active' : ''"
                  @click="handleSort('up')"
                ></div>
                <div
                  class="triangle_bottom_box"
                  :class="isActive === 'down' ? 'bottom_active' : ''"
                  @click="handleSort('down')"
                ></div>
              </div>
            </div>
          </div>
          <!-- 右边搜索 -->
          <div class="search_right">
            <el-input
              placeholder="请输入企业名称"
              v-model="queryInfo.keys"
              @input="handleInput"
              clearable
              @change="searchchange"
              class="input-with-select"
            >
              <el-button slot="append" @click="search" type="primary">搜索</el-button>
            </el-input>
          </div>
        </div>
      </el-form>
    </div>

    <el-table
      :data="list"
      style="width: 100%"
      class="in_table"
      @sort-change="sortChange"
      :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="企业名称" prop="companyFullName" align="center"></el-table-column>
      <el-table-column label="认领单位" prop="correlation"  align="center">
        <template v-slot="{ row }">
          <span>{{ row.unitName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所在地区" prop="" align="center">
        <template v-slot="{ row }">
          <span>{{ row.city }}-{{ row.district }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属行业" prop="companyLabel" align="center">
        <template v-slot="{ row }">
          <span v-if="row.industry">{{ row.industry }}</span>

          <span v-if="row.segmentedIndustry">-{{ row.segmentedIndustry }}</span>
        </template>
      </el-table-column>

      <el-table-column label="推送时间" prop="createTime" sortable align="center"></el-table-column>
    </el-table>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
  <script>
import { listPage } from '@/api/enterpriseCustomer.js'
import twoleventag from '@/components/twoleventag.vue'
import { getIndustryVo } from '@/api/visiting.js'
import CitySelect from '@/components/CitySelect.vue'
const defaultQueryInfo = Object.freeze({
  industry: '',
  segmentedIndustry: '',
  ids: [],
  city: '贵阳市', // 市
  district: '', // 区
  provinces: '贵州省', // 省
  pageNum: 1,
  pageSize: 10,
  orders: 1, // 排序 默认传1 2->关联企业升序;3->关联企业降序;4->登陆时间升序;5->登陆时间降序
  keys: '' //  姓名/电话
})
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { CitySelect, twoleventag },
  props: {
    companyList: {
      type: Array
    }
  },
  data() {
    //这里存放数据
    return {
      sortVal: 'up', // 排序点击事件的传参
      isActive: '', // 排序图标样式
      sortVals: 'up', // 排序点击事件的传参
      isActives: '', // 排序图标样式
      queryInfo: { ...defaultQueryInfo }, //查询传参
      list: [],
      total: 0,
      options: [], //所属行业
      childoptions: [] //所属行业子集
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //所属行业
    async getIndustryVo() {
      const res = await getIndustryVo()
      if (res.resultCode == 200) {
        this.options = res.data
        this.options.forEach((el) => {
          if (el.industryName == this.queryInfo.industry) {
            // console.log(el);
            if (el.children) {
              this.childoptions = el.children
            } else {
              this.childoptions = []
              this.queryInfo.segmentedIndustry = ''
            }
          }
        })
      }
    },
    changeVal(val) {
      if (!val) {
        this.queryInfo.segmentedIndustry = ''
        this.search()
        return
      }
      this.options.forEach((el) => {
        if (el.industryName == val) {
          // console.log(el);
          if (el.children) {
            this.childoptions = el.children
          } else {
            this.childoptions = []
            this.queryInfo.segmentedIndustry = ''
          }
        }
      })
      this.search()
    },
    async search() {
      if(!this.companyList.length){
        return
      }
      this.queryInfo.ids = this.companyList
      const res = await listPage(this.queryInfo)
      if (res.resultCode == 200) {
        this.list = res.data.list

        this.total = res.data.total
      }
    },
    //排序
    handleSort(val) {
      this.isActive = val
      if (val === 'up') {
        this.queryInfo.orders = 3
        this.sortVal = 'down'
        this.search()
      } else {
        this.queryInfo.orders = 2
        this.sortVal = 'up'
        this.search()
      }
    },
    //排序
    handleSorts(val) {
      this.isActives = val
      if (val === 'up') {
        this.queryInfo.orders = 5
        this.sortVals = 'down'
        this.search()
      } else {
        this.queryInfo.orders = 4
        this.sortVals = 'up'
        this.search()
      }
    },
    // 排序
    sortChange(val) {
      if (val.prop === 'createTime') {
        if (val.order === 'ascending') {
          this.queryInfo.orders = 3
        } else if (val.order === 'descending') {
          this.queryInfo.orders = 2
        } else {
          this.queryInfo.orders = 2
        }
      }
      this.search()
    },
    onChanges(val) {
      this.queryInfo.unit = val
      this.search()
    },
    handleInput(value) {
      this.queryInfo.keys = value.replace(/\s+/g, '')
    },
    searchchange(val) {
      if (!val) {
        this.search()
      }
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
      this.search()
    },
    //返回区/县
    searchDistrict(val) {
      this.queryInfo.district = val
      this.search()
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    }
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {
    this.getIndustryVo()
  }
  //生命周期 - 挂载完成(可以访问DOM元素)
}
</script>
  <style scoped lang="less">
::v-deep  .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #333333;
  line-height: 32px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  width: 90px;
}

::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #333333;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 8px 14px;
  font-size: 14px;
  border-radius: 0;
  top: -1px;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 0.0625rem solid #fff !important;
  border-radius: 0.25rem 0 0 0.25rem;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #448aff;
  border-radius: 4px;
}
::v-deep .el-radio-button .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px;
  box-shadow: none !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #448aff;
  border-radius: 4px;
}
::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
  margin: 1px;
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #8fb9ff;
  border-radius: 4px;
}

::v-deep .el-table::before {
  content: '';
  position: absolute;
  background-color: #fff;
  z-index: 1;
}

.info_box {
  width: 100%;
  height: calc(100vh - 250px);
  background: #fff;
  .progress_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .row_flex {
    display: flex;
    align-items: center;
    position: relative;
    top: 5px;
    .center_total {
      font-size: 16px;
    }
    .sort {
      display: flex;
      align-items: center;
      margin-left: 20px;
      span {
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        margin-right: 5px;
      }
      .sort_icon {
        cursor: pointer;
        display: flex;
        flex-flow: column;
        height: auto;
        i {
          width: 14px;
          height: 14px;
          color: #dbd6d6;
          font-size: 14px;
        }
        .isActive {
          color: #8a8888 !important;
        }
      }
    }
  }
}

.search_right {
  width: 460px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}

.item_list {
  display: flex;
  flex-direction: column;
  color: #4e93fb;

  .file_list {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    .file_name {
      span {
        border-bottom: 1px solid #4e93fb;
      }
    }
    ::v-deep .el-button {
      font-size: 16px;
    }
    .el-icon-download {
      font-size: 20px;
    }
  }
}
.demand {
  margin-bottom: 10px;
}
::v-deep .el-empty__description p {
  margin: 0;
  font-size: 18px;
  color: #909399;
}

.top_card {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0px 20px 0px 0px;
  padding-top: 10px;
  box-sizing: border-box;

  .btn_list {
    margin-left: 20px;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .color1 {
    color: #4e93fb;
  }
}
::v-deep .el-form-item {
  margin-bottom: 15px !important;
}

.in_table {
  height: calc(100vh - 445px);
  overflow: auto;
  // /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
}

.new_page {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
::v-deep .el-progress {
  width: 40px !important;
  height: 40px !important;
}
::v-deep .el-progress-circle {
  width: 40px !important;
  height: 40px !important;
}
</style>
  