<template>
  <div class="info_box">
    <div class="top_card">
      <el-form label-width="90px">
        <el-form-item label="认领单位：">
          <twoleventag @onChange="onChanges" />
        </el-form-item>
        <el-form-item label="所在地区：">
          <city-select
            ref="child"
            :province="queryInfo.provinces"
            :city="queryInfo.city"
            :district="queryInfo.district"
            @searchCity="searchCity"
            @searchDistrict="searchDistrict"
          />
        </el-form-item>
        <div class="row">
          <div class="row_flex">
      <div class="center_total">
        <span>共</span>
        <span class="color1">{{ total }}</span>
        <span>个客户经理</span>
      </div>
      <div class="sort">
        <span @click="handleSort(sortVal)">按推送时间</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActive === 'up' ? 'top_active' : ''"
            @click="handleSort('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActive === 'down' ? 'bottom_active' : ''"
            @click="handleSort('down')"
          ></div>
        </div>
      </div>
    </div>
          <!-- 右边搜索 -->
          <div class="search_right">
            <el-input
              placeholder="请输入客户经理姓名"
              v-model="queryInfo.customerName"
              @input="handleInputs"
              clearable
                @change="changesearch"
              class="input-with-select"
            >
              <el-button slot="append" @click="search" type="primary">搜索</el-button>
            </el-input>
          </div>
        </div>
      </el-form>
    </div>
   
    <el-table
      :data="list"
      style="width: 100%"
      class="in_table"
        @sort-change="sortChange"
      :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="客户经理" prop="customerName" align="center"></el-table-column>
      <el-table-column label="认领单位" prop="unit" align="center"></el-table-column>
      <el-table-column label="所在地区" prop="roleName" align="center">
        <template v-slot="{ row }">
          <span>{{ row.city }}-{{ row.district }}</span>
        </template>
      </el-table-column>
      <el-table-column label="手机号码" prop="customerPhone" align="center"></el-table-column>
      <el-table-column label="推送时间" prop="pushTime" sortable align="center"></el-table-column>
    </el-table>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
  <script>
import { pushManagerPage } from '@/api/plan.js'
import twoleventag from '@/components/twoleventag.vue'

import CitySelect from '@/components/CitySelect.vue'
const defaultQueryInfo = Object.freeze({
  city: '贵阳市',
  companyFullName: '',
  customerName: '',
  district: '',
  informationId: '',
  orders: 1,
  pageNum: 1,
  pageSize: 10,
  state: '',
  unitId: 0
})
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { CitySelect, twoleventag },
  props: {
    caseId: {
      type: String
    }
  },
  data() {
    //这里存放数据
    return {
      sortVal: 'up', // 排序点击事件的传参
      isActive: '', // 排序图标样式
      queryInfo: { ...defaultQueryInfo }, //查询传参
      list: [],
      total: 0
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async search() {
      this.queryInfo.informationId = this.caseId
      const res = await pushManagerPage(this.queryInfo)
      if (res.resultCode == 200) {
        this.list = res.data.list
        this.total = res.data.total
      }
    },
    onChanges(val) {
      this.queryInfo.unitId = val
      this.search()
    },
    handleInputs(value) {
      this.queryInfo.customerName = value.replace(/\s+/g, '')
    },
    changesearch(val){
      if(!val){
        this.search()
      }
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
      this.search()
    },
    //返回区/县
    searchDistrict(val) {
      this.queryInfo.district = val
      this.search()
    },
     //排序
     handleSort(val) {
      this.isActive = val
      if (val === 'up') {
        this.queryInfo.orders = 3
        this.sortVal = 'down'
        this.search()
      } else {
        this.queryInfo.orders = 2
        this.sortVal = 'up'
        this.search()
      }
    },
     // 排序
     sortChange(val) {
      if (val.prop === 'pushTime') {
        if (val.order === 'ascending') {
          this.queryInfo.orders = 3
        } else if (val.order === 'descending') {
          this.queryInfo.orders = 2
        } else {
          this.queryInfo.orders = 2
        }
      } 
      this.search()
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    }
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {
    this.search()
  },
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {}
}
</script>
  <style scoped lang="less">
::v-deep .el-descriptions-item__cell {
  font-size: 16px;
}
::v-deep .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
  color: #333333;
  padding: 20px;
  text-align: center;
}
::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: normal;
}
::v-deep .el-descriptions-item__label {
  width: 10%;
  text-align: center !important;
  font-weight: 550 !important;
}
::v-deep .el-radio-button .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px;
  box-shadow: none !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #448aff;
  border-radius: 4px;
}
::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
  margin: 1px;
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #8fb9ff;
  border-radius: 4px;
}
::v-deep  .el-table::before {
    content: "";
    position: absolute;
    background-color: #fff;
    z-index: 1;
}
.info_box {
  width: 100%;
  height: calc(100vh - 250px);
  background: #fff;
  .row_flex {
    display: flex;
    align-items: center;
    position: relative;
    top: 10px;
    .center_total {
      font-size: 16px;
    }
    .sort {
      display: flex;
      align-items: center;
      margin-left: 20px;
      span {
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        margin-right: 5px;
      }
      .sort_icon {
        cursor: pointer;
        display: flex;
        flex-flow: column;
        height: auto;
        i {
          width: 14px;
          height: 14px;
          color: #dbd6d6;
          font-size: 14px;
        }
        .isActive {
          color: #8a8888 !important;
        }
      }
    }
  }
}

.search_right {
  width: 460px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.item_list {
  display: flex;
  flex-direction: column;
  color: #4e93fb;

  .file_list {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    .file_name {
      span {
        border-bottom: 1px solid #4e93fb;
      }
    }
    ::v-deep .el-button {
      font-size: 16px;
    }
    .el-icon-download {
      font-size: 20px;
    }
  }
}
.demand {
  margin-bottom: 10px;
}
::v-deep .el-empty__description p {
  margin: 0;
  font-size: 18px;
  color: #909399;
}

.top_card {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0px 20px 0px 0px;
  padding-top: 10px;
  box-sizing: border-box;
  .btn_list {
    margin-left: 20px;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .color1 {
    color: #4e93fb;
  }
}
::v-deep .el-form-item {
  margin-bottom: 15px !important;
}
.in_table{
  height: calc(100vh - 500px);
  overflow: auto;
   // /* 滚动条整体部分 */
   &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
}

.new_page {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
</style>
  