<!-- 省份、城市、地区 -->
<!-- 直辖市格式:xx市-xx市-xx区 -->
<template>
    <div class="cascaderArea">
      <el-select
        v-model="select.province"
        placeholder="请选择省份"
        @change="changeProvince"
        class="mini-select"
      >
        <el-option value="" label="不限"></el-option>
        <el-option
          v-for="item in list.provinces"
          :key="item.id"
          :label="item.name"
          :value="item.name"
        ></el-option>
      </el-select>
      <el-select
        v-model="select.city"
        placeholder="请选择城市"
        @change="changeCity"
        
        class="mini-select"
      >
        <el-option value="" label="不限"></el-option>
        <el-option
          v-for="item in list.citys"
          :key="item.id"
          :label="item.name"
          :value="item.name"
        ></el-option>
      </el-select>
      <!-- <el-select
        v-model="select.district"
        placeholder="请选择地区"
        @change="changeDistrict"
        class="mini-select"
      >
        <el-option value="" label="不限"></el-option>
        <el-option
          v-for="item in list.districts"
          :key="item.id"
          :label="item.name"
          :value="item.name"
        ></el-option>
      </el-select> -->
    </div>
  </template>
  
  <script>
  import { getCityAll } from '@/api/common'
  export default {
    props: {
      province: {
        type: String,
        default: ''
      },
      city: {
        type: String,
        default: ''
      },
      district: {
        type: String
      },
      country: {
        type: String
      }
    },
    data() {
      return {
        list: {
          provinces: [],
          citys: [],
          districts: []
        },
        select: {
          province: '',
          city: "",
          district: null
        },
  
        bigCitys: ['北京市', '天津市', '上海市', '重庆市'] // 直辖市
      }
    },
    watch: {
      country(newVal) {
        if (newVal) {
          this.search()
        }
      },
      province(newVal) {
        if (newVal) {
          this.select.province = newVal
        }
      },
      city(newVal) {
        if (newVal) {
          this.select.city = newVal
        }
      },
      district(newVal) {
        if (newVal) {
          this.select.district = newVal
        }
      }
    },
    created() {
      this.search()
    },
    methods: {
      async search() {
        this.list.provinces = sessionStorage.getItem('area')
        if (!this.list.provinces) {
          const res = await getCityAll()
          sessionStorage.setItem('area', JSON.stringify(res.data))
        }
        this.list.provinces = JSON.parse(sessionStorage.getItem('area'))
        this.list.provinces= this.list.provinces.slice(1)
        if (this.province) {
          this.select.province = this.province
          this.changeProvince(this.province)
        }
        if (this.city) {
          this.select.city = this.city
          this.changeCity(this.city)
        }
        if (this.district) {
          this.select.district = this.district
          this.changeDistrict(this.district)
        }
      },
      changeProvince(val) {
        this.list.citys = []
        let bol = false
        this.bigCitys.forEach((item) => {
          if (val === item) {
            bol = true
          }
        })
        if (bol) {
          // 是直辖市的时候
          this.list.citys = this.list.provinces.filter((item) => {
            return item.name === val
          })
        } else {
          this.list.provinces.forEach((item) => {
            if (item.name === val) {
              this.list.citys = item.cityResults
            }
          })
        }
        this.$emit('searchProvince', val)
        this.select.city = ''
        this.$emit('searchCity',  this.select.cityval)
        this.select.district = ''
      },
      changeCity(val) {
        this.list.districts = []
        this.list.citys.forEach((item) => {
          if (item.name === val) {
            this.list.districts = item.cityResults
          }
        })
        this.$emit('searchCity', val)
        this.select.district = ''
      },
      changeDistrict(val) {
        this.$emit('searchDistrict', val)
      },
      clear() {
        this.select = {
          province: null,
          city: null,
          district: null
        }
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .cascaderArea {
    .el-select {
      margin-right: 10px;
    }
  }
  </style>
  