<template>
  <div
    class="customer_manager"
    v-loading="loading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="top_card">
      <el-form>
        <el-form-item label="所在地区：">
          <provincesSelect
            ref="child"
            :province="queryInfo.province"
            :city="queryInfo.city"
            :district="queryInfo.district"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
          />
        </el-form-item>
        <el-form-item label="发生时间：">
          <el-date-picker
            v-model="timelist"
            type="daterange"
            @change="valtime"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <div class="row" style="justify-content: space-between">
          <div class="btn_list">
            <el-button plain @click="exportDiagnoseGroup">批量导出</el-button>
            <div>
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
              >
                全选
              </el-checkbox>
            </div>
          </div>

          <!-- 右边搜索 -->
          <div class="search_right">
            <el-input
              placeholder="请输入案例标题"
              v-model="queryInfo.informationName"
              class="input-with-select"
              clearable
              @change="search()"
              @input="handleInput"
            >
              <el-button slot="append" @click="search" type="primary">搜索</el-button>
            </el-input>
          </div>
        </div>
      </el-form>
    </div>
    <div class="row">
      <div class="row_txt">
        <span>当前条件下共查出</span>
        <span class="color1">{{ total }}</span>
        <span>条案例数据</span>
      </div>
      <div class="sort">
        <span @click="handleSorts(sortVals)">按推客户经理数</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActives === 'up' ? 'top_active' : ''"
            @click="handleSorts('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActives === 'down' ? 'bottom_active' : ''"
            @click="handleSorts('down')"
          ></div>
        </div>
      </div>
      <div class="sort">
        <span @click="handleSort(sortVal)">按推送企业数</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActive === 'up' ? 'top_active' : ''"
            @click="handleSort('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActive === 'down' ? 'bottom_active' : ''"
            @click="handleSort('down')"
          ></div>
        </div>
      </div>
      <div class="sort">
        <span @click="handleSortss(sortValss)">按案例完整度</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActivess === 'up' ? 'top_active' : ''"
            @click="handleSortss('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActivess === 'down' ? 'bottom_active' : ''"
            @click="handleSortss('down')"
          ></div>
        </div>
      </div>
    </div>
    <div class="card_list">
      <div class="card_item" v-for="(item, index) in list" :key="index">
        <div class="card_left">
          <div class="item_checkbox">
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.checked1"
              label=""
            ></el-checkbox>
          </div>

          <div class="img_box">
            <img v-if="item.coverImages" :src="item.coverImages" alt="" />
            <img
              v-else
              src="../../../assets/image/anli1.png"
              alt=""
            />
          </div>
          <div class="info_box">
            <div class="row_flex">
              <div class="enterprise_name" @click="toEnterpriseDetails(item)">
                {{ item.informationName }}
              </div>
            </div>

            <div class="row_margin">
              <span>{{ item.province }}</span>

              <span v-if="item.city">-{{ item.city }}</span>
            </div>
            <div class="row_flex">
              <!-- <span class="enterprise_txt">{{ item.briefIntroduction }}</span> -->
              <span class="enterprise_txt" v-html="item.briefIntroduction"></span>
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="box_list">
            <div class="item" @click="toEnterpriseDetails(item,'second')">
              <div class="num">{{ item.pushManagerNum ? item.pushManagerNum : 0 }}</div>
              <div class="row">
                <span class="txt">推送客户经理数</span>
              </div>
            </div>
            <div class="item" @click="toEnterpriseDetails(item,'third')">
              <div class="num">{{ item.pushCompanyNum ? item.pushCompanyNum : 0 }}</div>
              <div class="row">
                <span class="txt">推送企业数</span>
              </div>
            </div>
          </div>
          <div class="btn_box">
            <div class="btn_list">
              <span @click="toEnterpriseDetails(item,'first')" class="color1">详情</span>
            </div>
            <div class="time_txt">
              <span>发生时间：{{ item.createTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
  
  <script>
import provincesSelect from '@/components/provincesSelect.vue'

import { listPage,exportPlan } from '@/api/plan.js'
const defaultQueryInfo = Object.freeze({
  city: '',
  endTime: '',
  informationName: '',
  orderByParam: null,
  orders: 1,
  pageNum: 1,
  pageSize: 10,
  province: '',
  startTime: ''
})

export default {
  name: 'case',
  components: { provincesSelect },
  data() {
    return {
      sortVal: 'up', // 排序点击事件的传参
      isActive: '', // 排序图标样式
      sortVals: 'up', // 排序点击事件的传参
      isActives: '', // 排序图标样式
      sortValss: 'up', // 排序点击事件的传参
      isActivess: '', // 排序图标样式
      checkAll: false,
      loading: false,
      isIndeterminate: false,
      ids: [],
      timelist: [],
      queryInfo: { ...defaultQueryInfo }, // 企业查询一览
      list: [], // 企业列表
      total: 0 // 企业总条数
    }
  },
  created() {
    this.search()
  },
  computed: {},
  methods: {
    valtime(val) {
      if (val) {
        this.queryInfo.startTime = val[0]
        this.queryInfo.endTime = val[1]
      } else {
        this.queryInfo.startTime = ''
        this.queryInfo.endTime = ''
      }
      this.search()
    },

    changeVal(val) {
      if (!val) {
        this.queryInfo.segmentedIndustry = ''
        this.search()
        return
      }
      this.options.forEach((el) => {
        if (el.industryName == val) {
          // console.log(el);
          if (el.children) {
            this.childoptions = el.children
          } else {
            this.childoptions = []
            this.queryInfo.segmentedIndustry = ''
          }
        }
      })
      this.search()
    },

    async search() {
      this.loading = true

      const res = await listPage(this.queryInfo)

      if (res.resultCode==200) {
        this.list = res.data.list

        this.total = res.data.total
        this.loading = false
        if(this.list){
            this.handleCheckAllChange(false)
        }
        
      } else {
        this.$message.warning('获取信息失败!')
        this.loading = false
      }
    },

    handleInput(value) {
      this.queryInfo.keys = value.replace(/\s+/g, '')
    },
    //排序
    handleSort(val) {
      this.isActive = val
      if (val === 'up') {
        this.queryInfo.orders = 3
        this.sortVal = 'down'
        this.search()
      } else {
        this.queryInfo.orders = 2
        this.sortVal = 'up'
        this.search()
      }
    },
      //排序
      handleSorts(val) {
      this.isActives = val
      if (val === 'up') {
        this.queryInfo.orders = 5
        this.sortVals = 'down'
        this.search()
      } else {
        this.queryInfo.orders = 4
        this.sortVals = 'up'
        this.search()
      }
    },
      //排序
      handleSortss(val) {
      this.isActivess = val
      if (val === 'up') {
        this.queryInfo.orders = 6
        this.sortValss = 'down'
        this.search()
      } else {
        this.queryInfo.orders = 5
        this.sortValss = 'up'
        this.search()
      }
    },
    async exportDiagnoseGroup() {
    
    this.loading=true
   let informationIds=this.ids
  
  
    const query={...this.queryInfo,informationIds}
    delete query.pageNum
    delete query.pageSize
    const res = await exportPlan(query)
    if (res) {
      this.loading=false
      let blob = new Blob([res])
      let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
      const a = document.createElement('a') //创建a标签
      a.style.display = 'none'
      a.href = href // 指定下载'链接
      let nowdata = new Date()
      let year = nowdata.getFullYear()
      let month = nowdata.getMonth() + 1
      let date = nowdata.getDate()
      let nowtime = year + '-'
      if (month < 10) {
        nowtime += '0'
      }
      nowtime += month + '-'
      if (date < 10) {
        nowtime += '0'
      }
      nowtime += date

      a.download = `${nowtime} 案例数据.xlsx` //指定下载文件名
      a.click() //触发下载
      window.URL.revokeObjectURL(a.href) //释放URL对象
      if (document.body.contains(a)) {
        document.body.removeChild(a) //释放标签
      }
    } else {
      this.loading=false
      this.$message.warning('下载失败!')
    }
  },
    handleCheckAllChange(bol) {
      //bol为布尔值，true是选中，false是取消
      if (bol) {
        this.list.forEach((item) => {
          //orgCompanyId
          this.ids.push(item.informationId)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.list.forEach((item) => {
          this.ids = []
          this.checkAll = false
          this.$set(item, 'checked1', false)
        })
      }

      this.isIndeterminate = false
    },
    // 多选框选中数据
    latechange(bol, row) {
      // orgCompanyId
      const index = this.ids.indexOf(row.informationId)
      if (index == -1) {
        //orgCompanyId
        this.ids.push(row.informationId)
      } else {
        this.ids.splice(index, 1)
      }
      if (bol) {
        if (this.ids.length === this.list.length) {
          this.isIndeterminate = false
          this.checkAll = true
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      } else {
        if (this.ids.length < 1) {
          this.isIndeterminate = false
          this.checkAll = false
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    },
    //返回省
    searchProvince(val) {
      this.queryInfo.province = val
      this.search()
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
      this.search()
    },
    //返回区/县
    // searchDistrict(val) {
    //   this.queryInfo.district = val

    //   this.listOperatorList()
    // },
    toEnterpriseDetails(item,str){
        this.$router.push({
        path: '/aidedmanagement/case/info',
        query: {
          id: item.informationId,
          managernum: item.pushManagerNum,
          companynum: item.pushCompanyNum,
          tabindex:str
        }
      })
    },
    // 排序
    sortChange(val) {
      if (val.prop === 'companyCount') {
        if (val.order === 'ascending') {
          this.managerQueryInfo.orders = 2
        } else if (val.order === 'descending') {
          this.managerQueryInfo.orders = 3
        } else {
          this.managerQueryInfo.orders = 1
        }
      } else if (val.prop === 'loginTime') {
        if (val.order === 'ascending') {
          this.managerQueryInfo.orders = 4
        } else if (val.order === 'descending') {
          this.managerQueryInfo.orders = 5
        } else {
          this.managerQueryInfo.orders = 1
        }
      }
      this.searchCustomerManager()
    }
  }
}
</script>
  
  <style lang="less" scoped>

.customer_manager {
  height: calc(100% - 0px);
  overflow: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
}

.new_page {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: #fafafa 1px solid;
  text-align: center;
}
.search_right {
  width: 460px;
  margin-right: 20px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.check {
  margin-left: auto;
  margin-right: 30px;
}
.btn_list {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button {
    margin-right: 10px;
  }
  span {
    margin-left: 10px;
    cursor: pointer;
  }
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}
.icon_box {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  img {
    width: 16px;
    height: 16px;
  }
  i {
    font-size: 16px;
  }
}
.el-radio-button ::v-deep .el-radio-button__inner {
  border-radius: 4px !important;
  border: 0 !important;
}
.row_txt{
  font-size: 16px;
}
.sort {
  display: flex;
  align-items: center;
  margin-left: 20px;
  span {
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    margin-right: 5px;
  }
  .sort_icon {
    cursor: pointer;
    display: flex;
    flex-flow: column;
    height: auto;
    i {
      width: 14px;
      height: 14px;
      color: #dbd6d6;
      font-size: 14px;
    }
    .isActive {
      color: #8a8888 !important;
    }
  }
}

.top_card {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 20px;
  box-sizing: border-box;

 
}

.card_list {
  display: flex;
  flex-flow: column;
  width: 100%;
 min-height: calc(100vh - 400px);
  .card_item {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    position: relative;
    .card_left {
      display: flex;
      align-items: center;
      padding-top: 10px;
      box-sizing: border-box;
      width: 48%;
      margin-right: 20px;

      .item_checkbox {
        line-height: 36px;
        margin: 0px 10px;
      }
      .img_box {
        width: 130px;
        height: 88px;
        margin-right: 10px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        img {
          width: 130px;
          height: 88px;
        }
      }
      .info_box {
        display: flex;
        justify-content: center;
        flex-flow: column;
        .row_margin{
          margin: 15px 0px;
          display: flex;
          align-items: center;
          font-size: 14px;
        }
        .row_flex{
          display: flex;
          align-items: center;
          font-size: 14px;
        }
        .enterprise_txt {
          max-width: 340px; /* 固定宽度超出隐藏 */
          overflow: hidden; /*  超出隐藏 */
          text-overflow: ellipsis; /*  超出以 ...显示 */
          display: -webkit-box;
          -webkit-line-clamp: 1; /* // 显示1行 */
          -webkit-box-orient: vertical;
        }
        .enterprise_name {
          cursor: pointer;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          max-width: 340px; /* 固定宽度超出隐藏 */
          overflow: hidden; /*  超出隐藏 */
          text-overflow: ellipsis; /*  超出以 ...显示 */
          display: -webkit-box;
          -webkit-line-clamp: 1; /* // 显示1行 */
          -webkit-box-orient: vertical;
        }
        .enterprise_name:hover {
          color: #448aff;
        }
      }
    }
    .card_right {
      flex: 1;
      display: flex;
      .box_list {
        display: flex;
        align-items: center;
        .item {
          cursor: pointer;
          display: flex;
          flex-flow: column;
          justify-content: center;
          text-align: center;
          margin-right: 30px;
          .num {
            font-weight: bold;
            font-size: 22px;
            color: #448aff;
            margin-bottom: 10px;
          }
          .txt {
            font-size: 15px;
            color: #151515;
          }
        }
      }
      .btn_box {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        margin-left: auto;
        margin-right: 10px;
        .time_txt {
          margin-top: 10px;
          color: #999999;
          font-size: 14px;
        }
        span:hover {
          opacity: 0.7;
        }
        .divs {
          margin-right: 10px;
        }

        .btn_list span {
          font-size: 15px;
        }
      }
    }
  }
}

.color1 {
  color: #4e93fb;
  font-size: 16px;
}
.color2 {
  color: #fd5469;
  font-size: 16px;
}
.color3 {
  color: #fc8b39;
  font-size: 16px;
}
</style>
  