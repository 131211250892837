<template>
  <div
    class="customer_manager"
    v-loading="loading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="top_card">
      <el-form >
        <!-- <div class="row">
          <el-form-item label="所在地区：">
            <city-select
              ref="child"
              :province="queryInfo.province"
              :city="queryInfo.city"
              :district="queryInfo.district"
              @searchCity="searchCity"
              @searchDistrict="searchDistrict"
            />
          </el-form-item>
          <el-form-item label="走访日期：">
            <el-date-picker
              v-model="queryInfo.time"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              @change="search"
            >
            </el-date-picker>
          </el-form-item>
         
        </div> -->
        <el-form-item label="诊断工具：">
          <div class="radio_group">
            <el-radio-group v-model="queryInfo.questionnaireId" @change="search">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button v-for="(item, index) in questList" :key="index" :label="item.id">{{
                item.questionnaireName
              }}</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item  label="行业标签：">
          <dic-tag-button
            :code.sync="industryName"
            query-name="industryCategoryList"
            name="赋能行业"
            tag="行业"
            @onChange="onChange"
          />
        </el-form-item>
        <el-form-item  label="行业：">
          <div class="flex_industry">
            <el-radio-group v-model="queryInfo.industry" @change="changeVal">
              <el-radio-button label>不限</el-radio-button>
              <el-radio-button
                v-for="(item, index) in options"
                :key="index"
                :label="item.industryName"
              ></el-radio-button>
            </el-radio-group>
            <div class="segmentedIndustry" v-if="queryInfo.industry == '工矿'">
              <span>子集：</span>
              <el-radio-group @change="search" v-model="queryInfo.segmentedIndustry">
                <!-- <el-radio-button label>不限</el-radio-button> -->
                <el-radio-button
                  v-for="(item, index) in childoptions"
                  :key="index"
                  :label="item.industryName"
                ></el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </el-form-item>
        <div class="open_hiddn" v-if="optionIdinfo.length" :class="isopen ? 'css_hiddn' : ''">
          <el-form-item label="企业规模：">
            <div class="radio_group">
              <el-radio-group v-model="optionIdinfo[0].value" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button
                  v-for="(item, index) in screeninfo.list1"
                  :key="index"
                  :label="item.id"
                  >{{ item.optionData }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </el-form-item>

          <el-form-item label="所属行业：">
            <div class="radio_group">
              <el-radio-group v-model="optionIdinfo[1].value" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button
                  v-for="(item, index) in screeninfo.list2"
                  :key="index"
                  :label="item.id"
                  >{{ item.optionData }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="行业类型：">
            <div class="radio_group">
              <el-radio-group v-model="optionIdinfo[2].value" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button
                  v-for="(item, index) in screeninfo.list3"
                  :key="index"
                  :label="item.id"
                  >{{ item.optionData }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="所在地区：">
            <div class="radio_group">
              <el-radio-group v-model="optionIdinfo[3].value" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button
                  v-for="(item, index) in screeninfo.list4"
                  :key="index"
                  :label="item.id"
                  >{{ item.optionData }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="已使用系统：">
            <div class="radio_group">
              <el-radio-group v-model="optionIdinfo[4].value" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button
                  v-for="(item, index) in screeninfo.list5"
                  :key="index"
                  :label="item.id"
                  >{{ item.optionData }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </el-form-item>
          <div class="row">
            <el-form-item label="满足需求：">
              <div class="radio_group">
                <el-radio-group v-model="optionIdinfo[5].value" @change="search">
                  <el-radio-button label="">全部</el-radio-button>
                  <el-radio-button
                    v-for="(item, index) in screeninfo.list6"
                    :key="index"
                    :label="item.id"
                    >{{ item.optionData }}</el-radio-button
                  >
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item label="替换意愿：" style="margin-left: 40px">
              <div class="radio_group">
                <el-radio-group v-model="optionIdinfo[6].value" @change="search">
                  <el-radio-button label="">全部</el-radio-button>
                  <el-radio-button
                    v-for="(item, index) in screeninfo.list7"
                    :key="index"
                    :label="item.id"
                    >{{ item.optionData }}</el-radio-button
                  >
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item
              label="已有意向系统/产品/服务商："
              style="margin-left: 40px"
              label-width="200px"
            >
              <div class="radio_group">
                <el-radio-group v-model="optionIdinfo[7].value" @change="search">
                  <el-radio-button label="">全部</el-radio-button>
                  <el-radio-button
                    v-for="(item, index) in screeninfo.list8"
                    :key="index"
                    :label="item.id"
                    >{{ item.optionData }}</el-radio-button
                  >
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item label="领导参与走访：" label-width="140px" style="margin-left: 40px">
              <div class="radio_group">
                <el-radio-group v-model="optionIdinfo[8].value" @change="search">
                  <el-radio-button label="">全部</el-radio-button>
                  <el-radio-button
                    v-for="(item, index) in screeninfo.list9"
                    :key="index"
                    :label="item.id"
                    >{{ item.optionData }}</el-radio-button
                  >
                </el-radio-group>
              </div>
            </el-form-item>
          </div>
          <el-form-item label="沟通进展：">
            <div class="radio_group">
              <el-radio-group v-model="optionIdinfo[9].value" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button
                  v-for="(item, index) in screeninfo.list12"
                  :key="index"
                  :label="item.id"
                  >{{ item.optionData }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="改造内容：">
            <div class="radio_group">
              <el-radio-group v-model="optionIdinfo[10].value" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button
                  v-for="(item, index) in screeninfo.list11"
                  :key="index"
                  :label="item.id"
                  >{{ item.optionData }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="商机金额：">
            <div class="radio_group">
              <el-radio-group v-model="optionIdinfo[11].value" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button
                  v-for="(item, index) in screeninfo.list10"
                  :key="index"
                  :label="item.id"
                  >{{ item.optionData }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </el-form-item>
        </div>
        <div class="openButton_flex">
          <div class="openButton" @click="openItem()">
            {{ openSpan }}
            <i :class="openicon"></i>
          </div>
        </div>

        <div class="row" style="justify-content: space-between">
          <div class="row_flex">
            <el-button  plain @click="exportDiagnoseGroup"
              >批量导出</el-button
            >
            <div >
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
            >
              全选
            </el-checkbox>
          </div>
          </div>
          <div class="search_right">
            <el-input
              placeholder="请输入企业名称或者客户经理名称"
              v-model="queryInfo.queryConditions"
              class="input-with-select"
              clearable
              @clear="search"
            >
              <el-button slot="append" @click="search" type="primary">搜索</el-button>
            </el-input>
          </div>
        </div>
      </el-form>
    </div>
    <div class="row">
      <div class="row_txt">
        <span>当前条件下共查出</span>
        <span class="color1">{{ total }}</span>
        <span>条走访记录</span>
      </div>
      <div class="sort">
        <span @click="handleSort(sortVal)">按走访时间</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActive === 'up' ? 'top_active' : ''"
            @click="handleSort('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActive === 'down' ? 'bottom_active' : ''"
            @click="handleSort('down')"
          ></div>
        </div>
      </div>
    </div>
    <div class="card_list">
      <div class="card_item" v-for="(item, index) in list" :key="index">
        <!-- <div class="card_top color_green" v-if="item.visitWay == '线上会议'">
          {{ item.visitWay }}
        </div>
        <div class="card_top color_blue" v-else-if="item.visitWay == '线下走访'">
          {{ item.visitWay }}
        </div>
        <div class="card_top color_purple" v-else-if="item.visitWay == '电话沟通'">
          {{ item.visitWay }}
        </div>
        <div class="card_top color_gray" v-else-if="item.visitWay == '其他'">
          {{ item.visitWay }}
        </div> -->
        <div class="card_left">
          <div class="item_checkbox">
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.checked1"
              label=""
            ></el-checkbox>
          </div>
          <div class="img_box">
            <img v-if="item.companyLogo" :src="item.companyLogo" alt="" />
            <img v-else src="../../../assets/image/enterprise-default-logo.png" alt="" />
          </div>
          <div class="info_box">
            <div class="row">
              <div class="enterprise_name">
                {{ item.companyFullName }}
              </div>
            </div>
            <div class="row">
              <div
                class="type_tag"
                v-for="(li, lx) in newIndustryLabel(item.industryLabel).slice(0, 2)"
                :key="lx"
              >
                {{ li }}
              </div>
              <el-popover placement="right" width="400" trigger="hover">
                <div class="row_tag">
                  <div
                    class="type_tag1"
                    v-for="(lo, lh) in newIndustryLabel(item.industryLabel).slice(2)"
                    :key="lh"
                  >
                    {{ lo }}
                  </div>
                </div>
                <div
                  class="type_tag"
                  slot="reference"
                  v-show="newIndustryLabel(item.industryLabel).length > 2"
                >
                  {{ newIndustryLabel(item.industryLabel).length - 2 }}+
                </div>
              </el-popover>
            </div>
            <div class="row">
              <div>
                <span>{{ item.district || '-' }}</span>
              </div>
              <span class="bottom_time">走访时间：{{ item.visitTime || '-' }}</span>
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="box_list">
            <div class="item">
              <div class="row">
                <span class="txt">接待人</span>
              </div>
              <div class="num" v-if="item.flag" @click="toVisitingDetails(item.id, 'one')">有</div>
              <div class="num1" v-else>无</div>
            </div>
            <div class="item">
              <div class="row">
                <span class="txt">参与人</span>
              </div>
              <div
                class="num"
                v-if="item.flag1"
                @click="toVisitingDetails(item.id, 'two')"
              >
                有
              </div>
              <div class="num1" v-else>无</div>
            </div>
            <div class="item">
              <div class="row">
                <span class="txt">需求信息</span>
              </div>
              <div
                class="num"
                v-if="item.requirementDescribe"
                @click="toVisitingDetails(item.id, 'three')"
              >
                有
              </div>
              <div class="num1" v-else>无</div>
            </div>
            <div class="item">
              <div class="row">
                <span class="txt">解决方案</span>
              </div>
              <div class="num" v-if="item.solutionFile" @click="toVisitingDetails(item.id, 'four')">
                有
              </div>
              <div class="num1" v-else>无</div>
            </div>
          </div>
          <div class="btn_box">
            <div class="set_manager">
              <span class="manager_name"> 客户经理： </span>
              <div class="middle_icon_box">
                <img v-if="item.customerHead" :src="item.customerHead" alt="" />
                <img v-else src="@/assets/image/wait-set-manager.png" alt="" />
              </div>
              <span class="manager_name">
                {{ item.customerName }}
              </span>
            </div>
            <div class="btn_list">
               <el-button type="text" @click="toVisitingDiagnostic(item)"> 走访详情 </el-button>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import DicTagButton from '@/components/DicTagButton.vue'

import {
  listPage,
  exportInterviewApi,
  getVisitQuestionParam,
  selectQuestionnaire,
  getIndustryVo,
  exportDiagnoseGroupApi,
  exportRecord
} from '@/api/visiting.js'

const defaultQueryInfo = Object.freeze({
  city: '贵阳市', // 省
  companyFullName: '', // 企业全称
  companyId: 0, // 企业主键
  customerId: 0, // 客户经理编号
  district: '', // 市
  province: '贵州省',
  industryCategoryList: [], // 国名经济行业标签
  labels: '', //
  orders: 4, // 排序
  pageNum: 1, // 页数
  pageSize: 10, // 每页数据条数
  queryConditions: '', // 企业名称或客户经理名称
  time: '', // 日期
  unitIds: [],
  questionnaireId: '',
  visitWay: '',
  industry: '',
  segmentedIndustry: '',
  optionIdList: []
})

export default {
  name: 'Visiting',
  // uploadLogo
  components: { DicTagButton },
  data() {
    return {
      industryName: 'industryName',
      loading: false,
      questList: [], //工具list
      sortVal: 'up', // 排序点击事件的传参
      isActive: '', // 排序图标样式
      uploading: false, // 上传文件loading
      echo: 0, // 0是新增，1是删除
      customerId: null, // 选中的客户经理id
      screeninfo: {},
      queryInfo: { ...defaultQueryInfo }, // 企业查询一览
      list: [], // 企业列表
      total: 0, // 企业总条数
      optionIdinfo: [],
      childoptions: [],
      options: [], //所属行业
      isopen: false,
      isIndeterminate: false, //显示半选图标
      checkAll: false, //显示全选图标
      companyIdList: [], // 选中的企业id
      openSpan: '展开筛选',
      openicon: 'el-icon-arrow-down'
    }
  },
  created() {
    this.getVisitQuestionParam()
    this.selectQuestionnaire()
    this.getIndustryVo()
  },
  computed: {},
  methods: {
    //所属行业
    async getIndustryVo() {
      const res = await getIndustryVo()
      if (res.resultCode == 200) {
        this.options = res.data
      }
    },
    changeVal(val) {
      if (!val) {
        this.queryInfo.segmentedIndustry = ''
        this.search()
        return
      }
      this.options.forEach((el) => {
        if (el.industryName == val) {
          // console.log(el);
          if (el.children) {
            this.childoptions = el.children
          } else {
            this.childoptions = []
            this.queryInfo.segmentedIndustry = ''
          }
        }
      })
      this.search()
    },
    openItem() {
      if (!this.isopen) {
        this.isopen = true
        this.openSpan = '收缩筛选'
        this.openicon = 'el-icon-arrow-up'
      } else {
        this.isopen = false
        this.openSpan = '展开筛选'
        this.openicon = 'el-icon-arrow-down'
      }
    },
    async selectQuestionnaire() {
      const res = await selectQuestionnaire()
      if (res.resultCode == 200) {
        this.questList = res.data
      }
    },
    //全选框
    handleCheckAllChange(bol) {
      //bol为布尔值，true是选中，false是取消
      if (bol) {
        this.list.forEach((item) => {
          //orgCompanyId
          this.companyIdList.push(item.diagnoseId)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.list.forEach((item) => {
          this.companyIdList = []
          this.checkAll = false
          this.$set(item, 'checked1', false)
        })
      }

      this.isIndeterminate = false
    },
     // 导出诊断记录
     async exportDiagnoseGroup() {
    
      // if(!this.companyIdList.length){
      //   this.$message.warning('请先选择企业!')
      //   return
      // }
      this.loading=true
      let ids=[]
      if(this.checkAll){
        ids=[]
      }else{
         ids=this.companyIdList
      }
    
    
      const query={...this.queryInfo,ids}
      delete query.pageNum
      delete query.pageSize
      const res = await exportRecord(query)
      if (res) {
        this.loading=false
        let blob = new Blob([res])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date

        a.download = `${nowtime} 走访记录.xlsx` //指定下载文件名
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.loading=false
        this.$message.warning('下载失败!')
      }
    },
     // 多选框选中数据
     latechange(bol, row) {
      // orgCompanyId
      const index = this.companyIdList.indexOf(row.diagnoseId)
      if (index == -1) {
        //orgCompanyId
        this.companyIdList.push(row.diagnoseId)
      } else {
        this.companyIdList.splice(index, 1)
      }
      if (bol) {
        if (this.companyIdList.length === this.list.length) {
          this.isIndeterminate = false
          this.checkAll = true
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      } else {
        if (this.companyIdList.length < 1) {
          this.isIndeterminate = false
          this.checkAll = false
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      }
    },
    //查询筛选数据
    async getVisitQuestionParam() {
      this.loading = true
      const res = await getVisitQuestionParam()
      if (res.resultCode == 200) {
        let list = res.data
        this.optionIdinfo = []
        list.forEach((el) => {
          this.optionIdinfo.push({
            value: ''
          })
          if (el.caption == '企业规模') {
            this.$set(this.screeninfo, 'list1', el.optionList)
          } else if (el.caption == '当前系统是否满足需求') {
            this.$set(this.screeninfo, 'list6', el.optionList)
          } else if (el.caption == '是否有替换意愿') {
            this.$set(this.screeninfo, 'list7', el.optionList)
          } else if (el.caption == '是否已有意向系统/产品/服务商') {
            this.$set(this.screeninfo, 'list8', el.optionList)
          } else if (el.caption == '公司领导是否参与走访') {
            this.$set(this.screeninfo, 'list9', el.optionList)
          } else if (el.caption == '所属行业') {
            this.$set(this.screeninfo, 'list2', el.optionList)
          } else if (el.caption == '行业类型') {
            this.$set(this.screeninfo, 'list3', el.optionList)
          } else if (el.caption == '所在地区') {
            this.$set(this.screeninfo, 'list4', el.optionList)
          } else if (el.caption == '商机金额') {
            this.$set(this.screeninfo, 'list10', el.optionList)
          } else if (el.caption == '企业已使用系统') {
            el.optionList.forEach((o) => {
              const dotIndex = o.optionData.indexOf('。')

              if (dotIndex != -1) {
              
                const result = o.optionData.substring(0, dotIndex)
                o.optionData = result
              }
            })
            this.$set(this.screeninfo, 'list5', el.optionList)
          } else if (el.caption == '客户意向转型改造内容') {
            this.$set(this.screeninfo, 'list11', el.optionList)
          } else if (el.caption == '走访沟通进展') {
            this.$set(this.screeninfo, 'list12', el.optionList)
          }
        })

        this.search()
      }
    },
    async search() {
      this.queryInfo.operatorId = sessionStorage.getItem('operatorId') || ''
      this.queryInfo.customerId = this.customer
      this.queryInfo.optionIdList = []
      this.optionIdinfo.forEach((el) => {
        if (el.value) {
          this.queryInfo.optionIdList.push(el.value)
        }
      })
      const res = await listPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.list = res.data.list
        this.list.forEach((el) => {
          if (el.receiverInfo) {
            el.receiverInfo = JSON.parse(el.receiverInfo)
            el.receiverInfo.forEach((o) => {
              if (o.name) {
                this.$set(el, 'flag', true)
              }
            })
          } else {
            this.$set(el, 'flag', false)
          }
          if (el.participantInfo) {
            el.participantInfo = JSON.parse(el.participantInfo)
            el.participantInfo.forEach((o) => {
              if (o.name) {
                this.$set(el, 'flag1', true)
              }
            })
          } else {
            this.$set(el, 'flag1', false)
          }
          this.$set(el, 'checked1', false)
        })
        if (this.checkAll) {
        this.list.forEach((item) => {
          //orgCompanyId
          this.companyIdList.push(item.diagnoseId)
          this.$set(item, 'checked1', true)
        })
      }else{
        this.list.forEach((item) => {
          this.companyIdList.forEach(el=>{
            if(item.diagnoseId==el){
              this.$set(item, 'checked1', true)
            }
          })
        
        })
      }
        this.total = res.data.total
        this.loading = false
      } else {
        this.$message.warning('获取企业信息失败!')
        this.loading = false
      }
    },
    // 数据处理
    newIndustryLabel(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },
    onChange(firstVal, val) {
      if (firstVal && val) {
        this.queryInfo.industryCategoryList = [firstVal]
        // this.queryInfo.industryCategoryList = val
        this.queryInfo.industryCategoryList = this.queryInfo.industryCategoryList.concat(val)
      } else if (firstVal && !val) {
        this.queryInfo.industryCategoryList = [firstVal]
      } else if (!val && !firstVal) {
        this.queryInfo.industryCategoryList = []
      }
      this.industryName = 'industryName'
      if (val) {
        this.search()
      }
      if (!val && firstVal) {
        this.search()
      }
      if (!val && !firstVal) {
        this.search()
      }
    },
    closeTag() {
      this.queryInfo.industryCategoryList = []
      this.industryName = ''
      this.search()
    },
    //排序
    handleSort(val) {
      this.isActive = val
      if (val === 'up') {
        this.queryInfo.orders = 4
        this.sortVal = 'down'
        this.search()
      } else {
        this.queryInfo.orders = 5
        this.sortVal = 'up'
        this.search()
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    },
    //返回省
    // searchProvince(val) {
    //   this.queryInfo.province = val
    //   this.search()
    // },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
      this.search()
    },
    //返回区/县
    searchDistrict(val) {
      this.queryInfo.district = val

      this.search()
    },
  
    //去详情页
    toVisitingDetails(val, type) {
      this.$router.push({
        path: '/service/visitingDetails',
        query: {
          id: val,
          type
        }
      })
    },
    //去诊断记录
    toVisitingDiagnostic(val) {
      if (val.evaluationType == 3) {
        this.$router.push({
          path: '/service/visitingDiagnostic',
          query: {
            id: val.id,
            diagnoseId: val.diagnoseId
          }
        })
      } else {
        this.$router.push({
          path: '/service/visitdetails',
          query: {
            id: val.id,
            diagnoseId: val.diagnoseId
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-checkbox__inner {
    display: inline-block;
    position: relative;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    background-color: #fff;
    z-index: 1;
    transition: border-color .25s cubic-bezier(.71, -.46, .29, 1.46), background-color .25s cubic-bezier(.71, -.46, .29, 1.46);
}
::v-deep .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #333333;
  line-height: 25px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  width: 100px;
}
::v-deep .el-form-item__content {
  line-height: 25px;
  position: relative;
  font-size: 14px;
}
::v-deep .flex_industry .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px;
  box-shadow: none !important;
}
::v-deep .openClose .openButton {
  width: 10%;
  line-height: 30px;
  color: #1e9fff;
  cursor: pointer;
  text-align: center;
}
::v-deep .openInfo {
  width: 90% !important;
  max-height: 35px;
  overflow: hidden;
}
::v-deep .newClass {
  width: 90%;
  max-height: 100px !important;
  transition: all 1s linear;
}
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #333333;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 6px 10px;
  font-size: 14px;
  border-radius: 0;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #448aff;
  border-radius: 4px;
}
::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
  margin: 1px;
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #8fb9ff;
  border-radius: 4px;
}
::v-deep .el-form-item {
  margin-bottom: 14px;
}
.customer_manager {
  height: 100vh;
}
::v-deep .el-table__header-wrapper .el-checkbox {
  display: none;
}
.el-form-item ::v-deep .el-form-item__label {
  color: #333333;
}
.el-form-item ::v-deep .el-form-item__content {
  display: flex;
}
.distpicker-address-wrapper ::v-deep select {
  width: 150px;
}
.distpicker-address-wrapper ::v-deep label {
  margin-right: 10px;
}
.el-form-item__content ::v-deep .upload-demo {
  width: 100px;
}
::v-deep .el-checkbox__inner::after {
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 9px;
    left: 6px;
    position: absolute;
    top: 1px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    transition: transform .15s ease-in 50ms;
    transform-origin: center;
}
::v-deep .el-button--info {
  color: rgb(0, 0, 0);
  background-color: #f4f4f4;
  border-color: #c9c9c9;
}
.new_page {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: #fafafa 1px solid;
  text-align: center;
}
.search_right {
  width: 460px;
  margin-right: 20px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}

.btn_list {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button {
    margin: 0px 20px;
  }
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .row_flex{
    display: flex;
    align-items: center;
    margin-left: 18px;
    div{
      margin: 0px 20px;
    }
  }
}

.sort {
  display: flex;
  align-items: center;
  margin-left: 20px;
  span {
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    margin-right: 5px;
  }
  .sort_icon {
    cursor: pointer;
    display: flex;
    flex-flow: column;
    height: auto;
    i {
      width: 14px;
      height: 14px;
      color: #dbd6d6;
      font-size: 14px;
    }
    .isActive {
      color: #8a8888 !important;
    }
  }
}

.top_card {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  .segmentedIndustry {
    margin-top: 10px;
  }
  .open_hiddn {
    max-height: 0px;
    transition: all 0.5s linear;
    overflow: hidden;
  }
  .css_hiddn {
    max-height: 900px;
    transition: all 0.5s linear;
  }
  .openButton_flex {
    display: flex;
    justify-content: center;
    color: #448aff;
    cursor: pointer;
  }

  .radio_group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .wrap_left {
      width: 90%;
    }
    .el-radio-group {
      display: flex;
      flex-flow: row wrap;
    }
    ::v-deep .el-radio-button {
      .el-radio-button__inner {
        border-radius: 4px !important;
        border: 0 !important;
      }
    }
  }
}

.card_list {
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: calc(100vh - 380px);

  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .po_reportingStatus {
    position: absolute;
    color: #f53939;
    top: 50%;
    transform: translateY(-50%);
    left: 28%;
    z-index: 999 !important;
    img {
      width: 76px;
      height: 60px;
    }
  }
  .card_item {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    position: relative;
    .card_top {
      position: absolute;
      top: 10px;
      left: 6px;
      height: 30px;
      min-width: 84px;
      color: #ffffff;
      z-index: 99999;
      text-align: center;
      line-height: 30px;
      border-radius: 15px 20px 20px 0;
    }
    .card_left {
      display: flex;
      align-items: center;
      padding-top: 10px;
      box-sizing: border-box;
      width: 550px;
      margin-right: 20px;
      .item_checkbox {
        line-height: 36px;
        margin: 0px 10px;
      }
      .img_box {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        img {
          width: 100px;
          height: 100px;
        }
        .tag_box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 20px;
          color: #fff;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          // background-color: #198754;
          // border-top-left-radius: 8px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          position: absolute;
          top: 10px;
          left: 0;
          z-index: 999;
        }
      }
      .info_box {
        display: flex;
        justify-content: center;
        flex-flow: column;
        .bottom_time {
          margin-left: 20px;
        }
        .enterprise_name {
          cursor: pointer;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          max-width: 340px; /* 固定宽度超出隐藏 */
          overflow: hidden; /*  超出隐藏 */
          text-overflow: ellipsis; /*  超出以 ...显示 */
          display: -webkit-box;
          -webkit-line-clamp: 1; /* // 显示1行 */
          -webkit-box-orient: vertical;
        }
        .enterprise_name:hover {
          color: #448aff;
        }

        .tag_list {
          display: flex;
          .tag_item {
            font-size: 10px;
            color: #4e93fb;
            border: #559eff 1px solid;
            border-radius: 4px;
            padding: 5px;
            margin-right: 6px;
          }

          .show_more {
            position: relative;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            cursor: pointer;
            .show_tag_box {
              position: absolute;
              top: 100%;
              left: 100%;
              z-index: 99;
              width: 240px;
              padding: 6px;
              box-sizing: border-box;
              border-radius: 4px;
              background-color: #fff;
              border: #f8f8f8 1px solid;
              display: flex;
              flex-flow: row wrap;
              li {
                padding: 3px 5px;
                .tag {
                  width: fit-content;
                  font-size: 10px;
                  line-height: 20px;
                  color: #4e93fb;
                  border: #559eff 1px solid;
                  border-radius: 4px;
                  padding: 0 6px;
                  box-sizing: border-box;
                }
              }
            }
          }
        }
      }
    }
    .card_right {
      flex: 1;
      display: flex;
      .box_list {
        display: flex;
        align-items: center;
        .item {
          display: flex;
          justify-content: center;
          text-align: center;
          margin-right: 30px;
          .num {
            cursor: pointer;
            font-weight: bold;
            font-size: 24px;
            color: #4e93fb;
            margin-bottom: 10px;
          }
          .num1 {
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            margin-bottom: 10px;
          }
          .txt {
            font-size: 16px;
            margin-right: 15px;
            color: #151515;
          }
        }
        // .item:hover {
        //   .num {
        //     color: #448aff;
        //   }
        //   .icon_box {
        //     color: #448aff;
        //   }
        //   span {
        //     color: #448aff;
        //   }
        // }
      }
      .btn_box {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 10px;

        .divs {
          margin-right: 10px;
        }
        .set_manager {
          display: flex;
          align-items: center;
          min-width: 120px;
          margin-right: 0px;
          .middle_icon_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #f4f4f4;
            overflow: hidden;
            margin-right: 10px;
            img {
              width: 35px;
              height: 35px;
            }
            i {
              font-size: 16px;
              color: #c9c9c9;
            }
          }
          .manager_name,
          .wait_set {
            cursor: pointer;
            font-size: 16px;
          }
        }
        .btn_list span {
          font-size: 14px;
        }
      }
    }
  }
}
.color_green {
  background-color: #0ecbc6;
}
.color_blue {
  background-color: rgb(68, 138, 255);
}
.color_purple {
  background-color: #6a70ff;
}
.color_gray {
  background-color: #a1a1a1;
}
.type_tag {
  font-size: 14px;
  color: #559eff;
  // text-align: center;
  margin-right: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #559eff;
}
.type_tag1 {
  font-size: 14px;
  color: #559eff;
  // text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #559eff;
}
.row_tag {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
}
.btn_color {
  color: #448aff;
  border-color: #448aff;
}
</style>
