<!-- 日志管理 -->
<template>
  <div class="log_management">
    <div class="top_journal">
      <el-form ref="form" :model="queryInfo" >
        <el-row :gutter="20">
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <el-form-item label="操作模块：">
                <div class="radio_group">
                  <el-radio-group v-model="queryInfo.operationModule" @change="search">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button
                      v-for="(item, index) in questList"
                      :key="index"
                      :label="item"
                      >{{ item }}</el-radio-button
                    >
                  </el-radio-group>
                </div>
              </el-form-item>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <el-form-item label="操作类型：">
                <div class="radio_group">
                  <el-radio-group v-model="queryInfo.operationType" @change="search">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button v-for="(item, index) in typeList" :key="index" :label="item">{{
                      item
                    }}</el-radio-button>
                  </el-radio-group>
                </div>
              </el-form-item>
            </div></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <el-form-item label="操作时间：">
                <el-date-picker
                  v-model="value1"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="changetime"
                >
                </el-date-picker>
              </el-form-item></div
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24"
            ><div class="rling">
              <el-form-item label="认领单位：">
                <twoleventag @onChange="onChanges" />
              </el-form-item></div
          ></el-col>
        </el-row>
      </el-form>

      <div class="right">
        <div class="left">
          <el-button plain @click="getexport">批量导出</el-button>
        </div>
        <div class="seach_box">
          <el-input
            placeholder="请输入客户经理名称或者关键词"
            clearable
            v-model="queryInfo.customerManagerName"
            @change="search()"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="search()">搜索</el-button>
          </el-input>
        </div>
      </div>
    </div>
    <div class="total_center">
      <span
        >当前条件下共查出 <span class="color_css">{{ total }}</span> 条操作日志</span
      >
      <div class="sort">
        <span @click="handleSort(sortVal)">按新增时间</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActive === 'up' ? 'top_active' : ''"
            @click="handleSort('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActive === 'down' ? 'bottom_active' : ''"
            @click="handleSort('down')"
          ></div>
        </div>
      </div>
    </div>
    <div class="table_back">
      <el-table
        v-loading="loading"
        :data="listData"
        stripe
        style="width: 100%"
        :row-key="getRowKey"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column type="selection" width="80" align="center"> </el-table-column>
        <el-table-column label="客户经理名称" width="120" align="center" prop="customerManagerName">
          <template v-slot="{ row }">
            <span class="customer_name">{{ row.customerManagerName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="认领单位" prop="unit" align="center"></el-table-column>

        <el-table-column label="操作模块" prop="operationModule" align="center"></el-table-column>

        <el-table-column label="操作类型" prop="operationType" align="center">
          <template v-slot="{ row }">
            <span>{{ row.operationType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作内容" prop="operationContent" align="center">
          <template v-slot="{ row }">
            <span>{{ row.operationContent }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="登录次数"
          sortable
          align="center"
          prop="loginCount"
        ></el-table-column>

        <el-table-column label="操作时间" prop="operationTime" sortable align="center">
          <template v-slot="{ row }">
            <span>{{ row.operationTime }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import twoleventag from '@/components/twoleventag.vue'

import { listPage, getexport, operationType, operationModule } from '@/api/system.js'
const defaultQueryInfo = Object.freeze({
  customerManagerName: '', //客户经理名称
  operationModule: '', //操作模块
  operationTimeEndTime: '', //操作结束时间
  operationTimeStartTime: '', //操作开始时间
  operationType: '', //操作类型
  orderByParam: 4, //排序字段
  pageNum: 1,
  pageSize: 10
})
export default {
  name: 'LogManagement',
  components: { twoleventag },
  data() {
    return {
      ids: [],
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      value1: [], //时间
      sortVal: 'up', // 排序点击事件的传参
      isActive: '', // 排序图标样式
      // 遮罩层
      questList: [], //操作模块
      typeList: [], //操作类型
      loading: false,

      // 总条数
      total: 0,
      DialogVisible: false
    }
  },
  created() {
    this.search()
    this.operationType()
    this.operationModule()
  },
  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      const res = await listPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    //排序
    handleSort(val) {
      this.isActive = val
      if (val === 'up') {
        this.queryInfo.orderByParam = 3
        this.sortVal = 'down'
        this.search()
      } else {
        this.queryInfo.orderByParam = 4
        this.sortVal = 'up'
        this.search()
      }
    },
    //操作模块
    async operationModule() {
      const res = await operationModule()
      if (res.resultCode == 200) {
        this.questList = res.data
      }
    },
    //操作类型
    async operationType() {
      const res = await operationType()
      if (res.resultCode == 200) {
        this.typeList = res.data
      }
    },
    changetime(val) {
      if (val) {
        this.queryInfo.operationTimeStartTime = val[0]
        this.queryInfo.operationTimeEndTime = val[1]
      } else {
        this.queryInfo.operationTimeStartTime = ''
        this.queryInfo.operationTimeEndTime = ''
      }
      this.search()
    },
    onChanges(val){
      this.queryInfo.unit=val
      this.search()
    },
    async getexport() {
      this.loading = true
      let query = {
        customerManagerName: this.queryInfo.customerManagerName, //排序 默认传1 2->提交/保存升序;3->提交/保存降序;4->操作时间升序;5->操作时间降序
        operationModule: this.queryInfo.operationModule, //合作伙伴编号
        operationTimeEndTime: this.queryInfo.operationTimeEndTime, //诊断状态（0：未诊断，1：已诊断）
        operationTimeStartTime: this.queryInfo.operationTimeStartTime, //评估类型(1:企业数智化转型诊断评估,2:企业数据化转型政策申报,3->调查问卷工具)
        operationType: this.queryInfo.operationType //提交状态（0：保存，1：提交）
      }

      if (this.ids.length) {
        query['ids'] = this.ids
      }
      const res = await getexport(query)

      if (res) {
        this.loading = false
        let blob = new Blob([res])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        console.log(href)
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `操作日志${nowtime}.xlsx` //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
        this.search()
        this.$refs.multipleTable.clearSelection()
        this.isIndeterminate = false
        this.checkAll = false
      } else {
        this.$message.warning('下载失败!')
      }
    },

    getRowKey(row) {
      return row.id
    },
    //多选
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
    },
    // 排序
    sortChange(val) {
      if (val.prop === 'operationContent') {
        if (val.order === 'ascending') {
          this.queryInfo.orderByParam = 1
        } else if (val.order === 'descending') {
          this.queryInfo.orderByParam = 2
        } else {
          this.queryInfo.orderByParam = 2
        }
      } else if (val.prop === 'operationTime') {
        if (val.order === 'ascending') {
          this.queryInfo.orderByParam = 3
        } else if (val.order === 'descending') {
          this.queryInfo.orderByParam = 4
        } else {
          this.queryInfo.orderByParam = 3
        }
      }
      this.search()
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep  .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #333333;
  line-height: 28px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  width: 100px;
}

::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #333333;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 8px 14px;
  font-size: 14px;
  border-radius: 0;
  top: -1px;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 0.0625rem solid #fff !important;
  border-radius: 0.25rem 0 0 0.25rem;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #448aff;
  border-radius: 4px;
}
::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
  margin: 1px;
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #8fb9ff;
  border-radius: 4px;
}
.seach_box {
  display: flex;
  margin: 15px 20px 0px 15px;

  .input-with-select {
    width: 400px;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}
.office {
  display: inline-block;
  background: #edf4ff;
  border: 1px solid #559eff;
  color: #4e93fb;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 3px;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}
.top_journal {
  background: #fff;
  padding: 10px;
  font-size: 16px !important;
  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      ::v-deep .el-button {
        height: 40px;
        margin-left: 18px;
      }
    }
  }
}
.total_center {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  font-size: 15px;
  .color_css {
    color: #448aff;
  }
  .sort {
    display: flex;
    align-items: center;
    margin-left: 20px;
    span {
      cursor: pointer;
      font-size: 15px;
      margin-right: 5px;
    }
    .sort_icon {
      cursor: pointer;
      display: flex;
      flex-flow: column;
      height: auto;
      i {
        width: 14px;
        height: 14px;
        color: #dbd6d6;
        font-size: 14px;
      }
      .isActive {
        color: #8a8888 !important;
      }
    }
  }
}
.table_back {
  background: #fff;
  padding: 10px;
  min-height: calc(100vh - 460px);
  .customer_name {
    color: #448aff;
  }
  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }
}

.edit {
  padding-left: 20px;
  color: #4e93fb;
  cursor: pointer;
}
.frozen {
  padding-left: 20px;
  color: #fd5469;
  cursor: pointer;
}

.new_page {
  background: #fff;
  padding: 20px 0px;
  width: 100%;
  text-align: center;
}
</style>