import RouterView from '@/components/RouterView'

const menuList = [{
    path: '/workbench',
    name: 'Workbench',
    component: RouterView,
    redirect: '/workbench',
    meta: {
      title: '工作台',
      isMenu: true
    },
    children: [{
      path: '/workbench',
      component: () => import('../views/workbench')
    }]
  },
  // {
  //   path: '/customermanager',
  //   name: 'CustomerManager',
  //   component: RouterView,
  //   redirect: '/customermanager',
  //   meta: {
  //     title: '客户经理',
  //     isMenu: true
  //   },
  //   children: [
  //     {
  //       path: '/customermanager',
  //       component: () => import('../views/customerManager')
  //     },
  //     {
  //       path: '/customermanager/addcustomermanager',
  //       name: 'AddCustomerManager',
  //       component: () => import('../views/customerManager/components/add.vue'),
  //       meta: {
  //         title: '经理详情',
  //         isMenu: true
  //       }
  //     }
  //   ]
  // },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: RouterView,
    redirect: '/enterpriseCustomer',
    meta: {
      title: '售前管理',
      isMenu: true
    },
    children: [
      {
        path: '/enterprise',
        redirect: '/enterpriseCustomer',
        component: () => import('../views/enterpriseCustomer/index.vue')
      },
      {
        name: 'EnterpriseCustomer',
        path: '/enterpriseCustomer',
      
        component: () => import('../views/enterpriseCustomer/index.vue'),
        meta: {
          title: '企业客户',
          isMenu: true
        }
      },

      {
        path: '/customermanager',
        name: 'CustomerManager',
        component: () => import('../views/customerManager/index.vue'),
        meta: {
          title: '客户经理',
          isMenu: true
        }
      },
      {
        path: '/customermanager/addcustomermanager',
        name: 'AddCustomerManager',
        component: () => import('../views/customerManager/components/add.vue'),
        meta: {
          title: '经理详情',
          isMenu: true
        }
      },
      {
        path: '/enterpriseCustomer/enterprisedetails',
        name: 'EnterpriseDetails',
        component: () => import('../views/enterpriseCustomer/components/enterpriseDetails'),
        meta: {
          title: '企业详情',
          isMenu: true
        }
      },
      // {
      //   path: '/enterpriseCustomer/enterprisedetails/recordinfo',
      //   name: 'RecordInfo',
      //   component: () => import('../views/enterpriseCustomer/components/recordInfo'),
      //   meta: {
      //     // 诊断记录详情
      //     title: '诊断记录',
      //     isMenu: true
      //   }
      // },
       {
        path: '/enterpriseCustomer/enterprisedetails/records',
        name: 'Records',
        component: () => import('../views/enterpriseCustomer/components/records'),
        meta: {
          // 诊断记录详情
          title: '诊断记录',
          isMenu: true
        }
      },
      {
        path: '/enterpriseCustomer/enterprisedetails/recordinfos',
        name: 'Recordinfos',
        component: () => import('../views/enterpriseCustomer/components/recordinfos'),
        meta: {
          // 诊断记录详情
          title: '诊断详情',
          isMenu: true
        }
      },
      {
        path: '/enterpriseCustomer/enterprisedetails/CorrelateUser',
        name: 'CorrelateUser',
        component: () => import('../views/enterpriseCustomer/components/CorrelateUser'),
        meta: {
          // 诊断记录详情
          title: '账号管理',
          isMenu: true
        }
      },
     
      
      {
        path: '/enterpriseCustomer/enterprisedetails/order',
        name: 'order',
        component: () => import('../views/enterpriseCustomer/components/order'),
        meta: {
          // 诊断记录详情
          title: '订单管理',
          isMenu: true
        }
      },
   
      {
        path: '/enterpriseCustomer/enterprisedetails/servicelogdetails',
        name: 'ServiceLogDetails',
        component: () => import('../views/enterpriseCustomer/components/serviceLogDetails'),
        meta: {
          // 服务记录详情
          title: '服务记录',
          isMenu: true
        }
      },
      {
        path: '/enterpriseCustomer/enterprisedetails/servicesuggestiondetails',
        name: 'ServiceSuggestionDetails',
        component: () => import('../views/enterpriseCustomer/components/serviceSuggestionDetails'),
        meta: {
          // 服务建议详情
          title: '服务建议',
          isMenu: true
        }
      },
    ]
  },
  {
    path: '/operate',
    name: 'Operate',
    component: RouterView,
    redirect: '/user/list',
    meta: {
      title: '运营管理',
      isMenu: true
    },
    children: [
      {
        meta: {
          title: '用户管理',
          isMenu: true
        },
        name: 'User',
        path: '/user/list',
        component: () => import('../views/operate/userinshi.vue')
      },
    
      {
        meta: {
          title: '订单管理',
          isMenu: true
        },
        name: 'order',
        path: '/operate/order',
        component: () => import('../views/operate/order.vue')
      },
     
    ]
  },
  {
    path: '/service',
    name: 'Service',
    component: RouterView,
    redirect: '/service/diagnosis',
    meta: {
      title: '走访管理',
      isMenu: true
    },
    children: [
      {
        name: 'diagnosis',
        path: '/service/diagnosis',
        component: () => import('../views/servicesmage/diagnosis'),
        meta: {
          title: '诊断服务',
          isMenu: true
        }
      },
      {
        name: 'consultant',
        path: '/service/consultant',
        component: () => import('../views/servicesmage/consultant'),
        meta: {
          title: '顾问服务',
          isMenu: true
        }
      },
      {
        name: 'onlinedetail',
        path: '/service/onlinedetail',
        component: () => import('../views/servicesmage/component/onlinedetail'),
        meta: {
          title: '详情',
          isMenu: true
        }
      },
      {
        name: 'content',
        path: '/service/content',
        component: () => import('../views/servicesmage/content'),
        meta: {
          title: '内容服务',
          isMenu: true
        }
      },
      {
        name: 'addcontent',
        path: '/service/addcontent',
        component: () => import('../views/servicesmage/content/components/addcontent.vue'),
        meta: {
          title: '编辑',
          isMenu: true
        }
      },
      {
        name: 'listUserCheckPage',
        path: '/service/listUserCheckPage',
        component: () => import('../views/servicesmage/content/components/checkCount.vue'),
        meta: {
          title: '查看人数',
          isMenu: true
        }
      },
      {
        name: 'listUserSharePage',
        path: '/service/listUserSharePage',
        component: () => import('../views/servicesmage/content/components/shareCount.vue'),
        meta: {
          title: '分享人数',
          isMenu: true
        }
      },

      {
        name: 'activity',
        path: '/service/activity',
        component: () => import('../views/servicesmage/activity'),
        meta: {
          title: '活动服务',
          isMenu: true
        }
      },
      {
        name: 'addactivity',
        path: '/service/addactivity',
        component: () => import('../views/servicesmage/activity/components/editActivity.vue'),
        meta: {
          title: '编辑',
          isMenu: true
        }
      },
      {
        name: 'checkCount',
        path: '/service/checkCount',
        component: () => import('../views/servicesmage/activity/components/checkCount.vue'),
        meta: {
          title: '查看人数',
          isMenu: true
        }
      },
      {
        name: 'shareCount',
        path: '/service/shareCount',
        component: () => import('../views/servicesmage/activity/components/shareCount.vue'),
        meta: {
          title: '分享人数',
          isMenu: true
        }
      },

      {
        name: 'accountManagement',
        path: '/service/accountManagement',
        component: () => import('../views/servicesmage/activity/components/accountManagement.vue'),
        meta: {
          title: '报名管理',
          isMenu: true
        }
      },

      {
        name: 'report',
        path: '/service/report',
        component: () => import('../views/servicesmage/report'),
        meta: {
          title: '报告服务',
          isMenu: true
        }
      },
      {
        name: 'reportDetails',
        path: '/service/eventDetails',
        component: () => import('../views/servicesmage/report/components/eventDetails.vue'),
        meta: {
          title: '编辑',
          isMenu: true
        }
      },
      {
        name: 'reportcheck',
        path: '/service/reportcheck',
        component: () => import('../views/servicesmage/report/components/checkCount.vue'),
        meta: {
          title: '查看人数',
          isMenu: true
        }
      },
      {
        name: 'reportshare',
        path: '/service/reportcheck',
        component: () => import('../views/servicesmage/report/components/shareCount.vue'),
        meta: {
          title: '分享人数',
          isMenu: true
        }
      },
      {
        name: 'downloadCount',
        path: '/service/downloadCount',
        component: () => import('../views/servicesmage/report/components/downloadCount.vue'),
        meta: {
          title: '下载人数',
          isMenu: true
        }
      },

      {
        name: 'expert',
        path: '/service/expert',
        component: () => import('../views/servicesmage/expert'),
        meta: {
          title: '专家咨询',
          isMenu: true
        }
      },
      {
        name: 'expertCheckCount',
        path: '/service/expert/checkCount',
        component: () => import('../views/servicesmage/expert/component/checkCount'),
        meta: {
          title: '查看人数',
          isMenu: true
        }
      },
      {
        name: 'expertSharing',
        path: '/service/expert/sharing',
        component: () => import('../views/servicesmage/expert/component/sharing'),
        meta: {
          title: '分享人数',
          isMenu: true
        }
      },
      {
        name: 'consultantsNumber',
        path: '/service/expert/consultantsNumber',
        component: () => import('../views/servicesmage/expert/component/consultantsNumber.vue'),
        meta: {
          title: '咨询人数',
          isMenu: true
        }
      },
      {
        name: 'addexpert',
        path: '/service/addexpert',
        component: () => import('../views/servicesmage/expert/component/addexpert'),
        meta: {
          title: '编辑专家',
          isMenu: true
        }
      },
      {
        name: 'visiting',
        path: '/service/visiting',
        component: () => import('../views/servicesmage/visiting/index.vue'),
        meta: {
          title: '走访记录',
          isMenu: true
        }
      },
      {
        name: 'statistical',
        path: '/service/statistical',
        component: () => import('../views/servicesmage/visiting/statistical.vue'),
        meta: {
          title: '走访统计',
          isMenu: true
        }
      },
      {
        name: 'visitingDetails',
        path: '/service/visitingDetails',
        component: () => import('../views/servicesmage/visiting/components/visitingDetails.vue'),
        meta: {
          title: '详情',
          isMenu: true
        }
      },
      {
        name: 'visitingDiagnostic',
        path: '/service/visitingDiagnostic',
        component: () => import('../views/servicesmage/visiting/components/diagnosticRecords.vue'),
        meta: {
          title: '走访详情',
          isMenu: true
        }
      },
      {
        name: 'visitdetails',
        path: '/service/visitdetails',
        component: () => import('../views/servicesmage/visiting/components/visitdetails.vue'),
        meta: {
          title: '走访详情',
          isMenu: true
        }
      },

      // {
      //   name: 'consultant',
      //   path: '/service/consultant',
      //   component: () => import('../views/servicesmage/consultant'),
      //   meta: {
      //     title: '顾问服务',
      //     isMenu: true
      //   }
      // },
    ]
  },
  {
    path: '/aidedmanagement',
    name: 'aidedmanagement',
    component: RouterView,
    redirect: '/aidedmanagement/case',
    meta: {
      title: '辅助管理',
      isMenu: true
    },
    children: [
      {
        path: '/aidedmanagement',
        redirect: '/aidedmanagement/case',
        component: () => import('../views/aidedmanagement/case/index.vue')
      },
      {
        name: 'case',
        path: '/aidedmanagement/case',
        component: () => import('../views/aidedmanagement/case/index.vue'),
        meta: {
          title: '案例数据',
          isMenu: true
        }
      },
      {
        name: 'caseinfo',
        path: '/aidedmanagement/case/info',
        component: () => import('../views/aidedmanagement/case/components/caseinfo.vue'),
        meta: {
          title: '案例详情',
          isMenu: true
        }
      },
      {
        name: 'service',
        path: '/aidedmanagement/service/indedx',
        component: () => import('../views/aidedmanagement/service/index.vue'),
        meta: {
          title: '服务记录',
          isMenu: true
        }
      },
      {
        name: 'serviceinfo',
        path: '/aidedmanagement/service/serviceinfo',
        component: () => import('../views/aidedmanagement/service/serviceinfo.vue'),
        meta: {
          title: '服务记录详情',
          isMenu: true
        }
      },
      {
        name: 'clue',
        path: '/aidedmanagement/clue',
        component: () => import('../views/aidedmanagement/clue/index.vue'),
        meta: {
          title: '潜在线索',
          isMenu: true
        }
      },
      {
        name: 'clueinfo',
        path: '/aidedmanagement/clue/info',
        component: () => import('../views/aidedmanagement/clue/components/caseinfo.vue'),
        meta: {
          title: '潜在线索详情',
          isMenu: true
        }
      },
    ]
  },
  {
    path: '/system',
    name: 'System',
    component: RouterView,
    redirect: '/system/banner',
    meta: {
      title: '系统管理',
      isMenu: true
    },
    children: [{
        path: '/system',
        redirect: '/system/banner',
        component: () => import('../views/system')
      },
      {
        name: 'Banner',
        path: '/system/banner',
        component: () => import('../views/system/banner/index'),
        meta: {
          title: 'banner管理',
          isMenu: true
        }
      },
      {
        name: 'User',
        path: '/system/user',
        component: () => import('../views/system/user/index'),
        meta: {
          title: '系统用户',
          isMenu: true
        }
      },
      {
        name: 'Rights',
        path: '/system/rights',
        component: () => import('../views/system/rights/index'),
        meta: {
          title: '权限管理',
          isMenu: true
        }
      },
      {
        path: '/system/menumanagement',
        name: 'menuManagement',
        component: () => import('../views/system/menuManagement/index.vue'),
        meta: {
          title: '菜单中心'
        }
      },
      {
        name: 'LogManagement',
        path: '/system/logManagement',
        component: () => import('../views/system/logManagement/index'),
        meta: {
          title: '日志管理',
          isMenu: true
        }
      },

    ]
  },
]

export default menuList
