import { render, staticRenderFns } from "./serviceinfo.vue?vue&type=template&id=1d078f70&scoped=true"
import script from "./serviceinfo.vue?vue&type=script&lang=js"
export * from "./serviceinfo.vue?vue&type=script&lang=js"
import style0 from "./serviceinfo.vue?vue&type=style&index=0&id=1d078f70&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d078f70",
  null
  
)

export default component.exports